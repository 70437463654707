import React, { useEffect, useContext, useState } from "react";
import { GlobalState } from "../../Data/Context";
import { Container } from "reactstrap";
import { ModalComponents } from "../../Components";
import { toast } from "react-toastify";
import { useValidation } from "../../Data/useFetch";
import { Buttons, EmptyComponent } from "../../Utils";
import { BiTrashAlt } from "react-icons/bi";
import { TransactionPinBox } from "../../Components/Products/AutoBuy";
import LoadMore, { BottomTab } from "../../Components/LoadMore";
import moment from "moment";
import {
  MainPaginate,
  MainRanger,
  TransactionToppers,
} from "../../Components/Transactions";
import { NumericFormat } from "react-number-format";
import { ExportButtons2 } from "../../Utils/Export Functions/ExportButtons";
import { useParams } from "react-router-dom";

const Withdrawal = () => {
  let { setStateName, withdrawal, manageBanks, auth } = useContext(GlobalState);
  useEffect(() => {
    setStateName("Withdrawal");
    manageBanks("all-withdrawal");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let [isDelete, setIsDeleted] = useState(false),
    [isOpen, setIsOpen] = useState(false),
    toggle = () => {
      setIsOpen(!isOpen);
    };

  let [isBank, setIsBank] = useState(false),
    [loading, setLoading] = useState(false),
    [active, setActive] = useState(0),
    [submit, setSubmit] = useState(false),
    handleSubmitCard = async (e) => {
      if (e) e.preventDefault();

      setLoading(true);

      await manageBanks("delete", withdrawal?.bank);
      setLoading(false);
      setSubmit(true);
    };

  useEffect(() => {
    if (submit && withdrawal?.isDeleted) {
      setIsDeleted(false);
      setSubmit(false);
    }
  }, [withdrawal?.isDeleted, submit]);

  return (
    <>
      <div className="bg-white aboutScreen">
        <Container className="py-3 py-md-5">
          {!auth?.user?.isAdmin || auth?.user?.privilege === "superadmin" ? (
            <Buttons
              title={"withdraw"}
              css="btn-primary1 text-capitalize py-3 px-4 px-lg-5"
              width={"w-25 w25"}
              onClick={toggle}
              style={{ borderRadius: "30px" }}
            />
          ) : null}
          <div className="d-flex justify-content-end my-3">
            <div className="">
              {withdrawal?.bank ? (
                <>
                  <div
                    style={{
                      minWidth: "300px",
                    }}
                    className="rounded shadow p-3"
                  >
                    <h5>Bank: {withdrawal?.bank?.bank_name}</h5>
                    <h5>Account name: {withdrawal?.bank?.account_name}</h5>
                    <h5>Account number: {withdrawal?.bank?.account_number}</h5>
                    <BiTrashAlt
                      size={24}
                      color="red"
                      className="myCursor"
                      onClick={() => setIsDeleted(true)}
                    />
                  </div>
                </>
              ) : !auth?.user?.isAdmin ||
                auth?.user?.privilege === "superadmin" ? (
                <button
                  onClick={() => setIsBank(true)}
                  className="btn btn-outline-primary1 text-capitalize p-3 px-md-5"
                >
                  add bank
                </button>
              ) : null}
            </div>
          </div>
          <h5 className="Lexend my-3">Withdrawal History</h5>
          {auth?.user?.privilege === "superadmin" ? (
            <TransactionToppers
              title={"withdrawal"}
              active={active}
              setActive={setActive}
            />
          ) : null}
          {active === 1 ? <AllWithdrawalHistory /> : <WithdrawalHistory />}
        </Container>{" "}
      </div>
      <AddBank isOpen={isBank} toggle={() => setIsBank(false)} />
      <ModalComponents
        isOpen={isDelete}
        toggle={() => setIsDeleted(false)}
        title={"Delete Bank Account"}
      >
        <p className="text-center">Do you want to delete the connected bank?</p>
        <div className="d-flex">
          <div className="btn-group mx-auto">
            <Buttons
              title={"yes"}
              width={"auto"}
              loading={loading}
              onClick={handleSubmitCard}
              css={"btn-success2 text-uppercase p-3 px-md-4"}
            />
            <button
              onClick={() => setIsDeleted(false)}
              className="btn btn-danger2 text-uppercase p-3 px-md-4"
            >
              no
            </button>
          </div>
        </div>
      </ModalComponents>
      <MakeWithdraw isOpen={isOpen} back={toggle} />
    </>
  );
};

export default Withdrawal;

const AddBank = ({ isOpen, toggle }) => {
  let { withdrawal, converter, manageBanks } = useContext(GlobalState);
  let init = {
      account_number: "",
      account_name: "",
      bank_name: "",
      bank_code: "",
    },
    [loading, setLoading] = useState(false),
    [newState, setNewState] = useState(false),
    [submit, setSubmit] = useState(false),
    [state, setState] = useState(init),
    textChange =
      (name) =>
      ({ target: { value } }) => {
        setState({ ...state, [name]: value });
      },
    handleSubmitCard = async (e) => {
      if (e) e?.preventDefault();
      if (
        !state?.bank_code &&
        !state?.bank_name &&
        !state?.account_name &&
        !state?.account_number
      )
        return toast.info("Please provide bank name and account number", {
          autoClose: 10000,
        });

      let findIt = converter?.banks?.find(
        (item) => item?.code?.toString() === state?.bank_code?.toString()
      );

      setLoading(true);

      await manageBanks("post", { ...state, bank_name: findIt?.name });
      setLoading(false);
      setSubmit(true);
    },
    { validateLoading, handleFetch } = useValidation(
      "banks",
      state,
      setNewState
    );

  useEffect(() => {
    if (state?.account_number?.length === 10 && state?.bank_code) handleFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.account_number, state?.bank_code]);

  useEffect(() => {
    if (state?.bank_code) {
      converter?.banks?.map(
        (item) =>
          item?.code?.toString() === state?.bank_code?.toString() &&
          setState({ ...state, bank_name: item?.name })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.bank_code, converter]);

  useEffect(() => {
    if (validateLoading) {
      setState({ ...state, bank_name: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validateLoading]);

  useEffect(() => {
    if (newState) {
      setState({
        ...state,
        account_name: newState?.data?.account_name,
        account_number: newState?.data?.account_number,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newState]);
  // console.log({newState});
  useEffect(() => {
    if (submit && withdrawal?.isAdded) {
      setSubmit(false);
      setState(init);
      toggle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [withdrawal, submit]);

  return (
    <>
      <ModalComponents isOpen={isOpen} back={toggle} title="Add Bank">
        <form className="mt-4 g-3 g-md-5" onSubmit={handleSubmitCard}>
          <div className="mb-3">
            <label className="text-capitalize" htmlFor="bank_code">
              Bank Name
            </label>
            <select
              className="form-control py-3 py-md-4 bg-transparent text-capitalize form-select"
              name="bank_code rounded20"
              placeholder="Bank Name"
              value={state?.bank_code}
              onChange={textChange("bank_code")}
              readOnly={validateLoading}
              id="bank_code"
            >
              <option value="">select bank</option>
              {converter?.banks?.map((item, i) => (
                <option value={item?.code} key={i}>
                  {item?.name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-3">
            <label className="text-capitalize" htmlFor="name">
              Account Number
            </label>
            {/* <input
							type="number"
							className="form-control py-3 py-md-4 bg-transparent"
							placeholder="Account Number"
							required
							name="account_number"
							readOnly={validateLoading}
							value={state?.account_number}
							onChange={textChange("account_number")}
							maxLength={10}
						/> */}
            <NumericFormat
              className="form-control py-3 py-md-4 bg-transparent rounded20"
              value={state.account_number}
              allowLeadingZeros={true}
              allowNegative={false}
              displayType="input"
              name="account_number"
              onValueChange={(val) =>
                setState({ ...state, account_number: val?.value })
              }
              readOnly={validateLoading}
              maxLength={10}
              decimalScale={0}
              inputMode="tel"
              renderText={(value, props) => <span {...props}>{value}</span>}
            />
          </div>
          {state?.account_name && state?.account_number?.length === 10 && (
            <div className="mb-3">
              <label className="text-capitalize" htmlFor="name">
                Account name
              </label>
              <input
                type="text"
                required
                name="account_name"
                readOnly
                className="form-control py-3 py-md-4 bg-transparent"
                value={state?.account_name}
                onChange={textChange("account_name")}
              />
            </div>
          )}
          <div>
            <Buttons
              title={"add"}
              css="btn-primary1 text-capitalize py-3 w-50 my-4 mx-auto"
              width={"w-50"}
              style={{ borderRadius: "30px" }}
              loading={loading}
              onClick={handleSubmitCard}
            />
          </div>
        </form>
      </ModalComponents>
    </>
  );
};

const MakeWithdraw = ({ isOpen, back }) => {
  let {
      wallet,
      manageWithdrawal,
      withdrawal,
      nairaSign,
      numberWithCommas,
      nairaSignNeutral,
    } = useContext(GlobalState),
    init = {
      amount: "",
      narration: "",
      pin: "",
    },
    [state, setState] = useState(init),
    textChange =
      (name) =>
      ({ target: { value } }) => {
        setState({ ...state, [name]: value });
      },
    [loading, setLoading] = useState(false),
    [submit, setSubmit] = useState(false),
    handleSubmit = async (e) => {
      if (e) e?.preventDefault();
      setLoading(true);
      await manageWithdrawal("post", state);
      setLoading(false);
      setSubmit(true);
    },
    [active, setActive] = useState(0);

  useEffect(() => {
    if (submit && wallet?.isAdded) {
      setSubmit(false);
      back();
      setState(init);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [withdrawal?.isAdded, submit]);

  useEffect(() => {
    if (state?.pin && state?.pin?.length === 4) handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.pin]);

  return (
    <>
      <ModalComponents isOpen={isOpen} toggle={back} title="Withdrawal process">
        <form onSubmit={handleSubmit}>
          {active === 2 ? (
            <>
              <TransactionPinBox
                state={state}
                setState={setState}
                handleSubmit={handleSubmit}
                loading={loading}
                title={"withdraw"}
              />
            </>
          ) : active === 1 ? (
            <>
              <p className="text-capitalize border-bottom d-flex justify-content-between printOnlyNone">
                <span>Amount: </span>
                <span className="fontInherit Lexend">
                  {nairaSign}{" "}
                  {numberWithCommas(Number(state?.amount).toFixed(2))}
                </span>{" "}
              </p>
              <p className="text-capitalize border-bottom d-flex justify-content-between printOnlyNone">
                <span>Narration: </span>
                <span className="fontInherit Lexend">
                  {state?.narration}
                </span>{" "}
              </p>
              {withdrawal?.bank ? (
                <>
                  <p className="text-capitalize border-bottom d-flex justify-content-between printOnlyNone">
                    <span>Bank: </span>
                    <span className="fontInherit Lexend">
                      {withdrawal?.bank?.bank_name}
                    </span>{" "}
                  </p>
                  <p className="text-capitalize border-bottom d-flex justify-content-between printOnlyNone">
                    <span>Account name: </span>
                    <span className="fontInherit Lexend">
                      {withdrawal?.bank?.account_name}
                    </span>{" "}
                  </p>
                  <p className="text-capitalize border-bottom d-flex justify-content-between printOnlyNone">
                    <span>Account number: </span>
                    <span className="fontInherit Lexend">
                      {withdrawal?.bank?.account_number}
                    </span>{" "}
                  </p>
                </>
              ) : (
                <></>
              )}
              <div className="d-flex justify-content-end">
                <Buttons
                  title={"back"}
                  css="btn-outline-primary1 text-capitalize"
                  width={"w-auto"}
                  onClick={() => {
                    setActive(0);
                  }}
                />
              </div>
              <Buttons
                title={
                  withdrawal?.bank ? "proceed" : "please add a bank to proceed"
                }
                css="btn-primary1 text-capitalize py-3 w-50 my-4 mx-auto"
                width={"w-50"}
                disabled={!withdrawal?.bank}
                style={{ borderRadius: "30px" }}
                onClick={withdrawal?.bank ? () => setActive(2) : null}
              />
            </>
          ) : (
            <>
              <div className="mb-4">
                <label htmlFor="amount">Amount</label>
                {/* <input
									type="number"
									name="amount"
									id="amount"
									className="form-control py-3"
									value={state?.amount}
									onChange={textChange("amount")}
								/> */}
                <NumericFormat
                  prefix={`${nairaSignNeutral} `}
                  className="form-control py-3"
                  value={state?.amount}
                  placeholder="500"
                  displayType="input"
                  thousandSeparator={true}
                  onValueChange={(val) =>
                    setState({ ...state, amount: val?.floatValue })
                  }
                  min={0}
                  inputMode="decimal"
                  renderText={(value, props) => <span {...props}>{value}</span>}
                  allowNegative={false}
                />
              </div>
              <div className="mb-4">
                <label htmlFor="narration">Narration</label>
                <textarea
                  name="narration"
                  id="narration"
                  style={{
                    height: "10rem",
                    resize: "none",
                  }}
                  className="form-control py-3"
                  value={state?.narration}
                  onChange={textChange("narration")}
                />
              </div>
              <Buttons
                title={"proceed"}
                css="btn-primary1 text-capitalize py-3 w-50 my-4 mx-auto"
                width={"w-50"}
                style={{ borderRadius: "30px" }}
                onClick={() => {
                  if (!state?.amount) return;
                  setActive(1);
                }}
              />
            </>
          )}
        </form>
      </ModalComponents>
    </>
  );
};

const WithdrawalHistory = () => {
  let { withdrawal, manageWithdrawal, numberWithCommas, nairaSign } =
    useContext(GlobalState);

  let [data, setData] = useState(null);

  useEffect(() => {
    setData(withdrawal?.data);
  }, [withdrawal?.data]);

  useEffect(() => {
    manageWithdrawal("get");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let [loading, setLoading] = useState(false);
  // let handleLoadMore = async () => {
  //   setLoading(true);

  //   await manageWithdrawal("get", {
  //     page: Number(withdrawal?.paginate?.nextPage),
  //     // limit: Number(
  //     // 	withdrawal?.paginate?.nextPage * withdrawal?.paginate?.limit
  //     // ),
  //   });
  //   setLoading(false);
  // };

  // let [range, setRange] = useState(10);
  const params = useParams();

  const [pageCount, setPageCount] = useState(
    withdrawal?.paginate
      ? Math.ceil(withdrawal?.paginate?.total / withdrawal?.paginate?.limit)
      : 1
  );

  useEffect(() => {
    setPageCount(
      Math.ceil(withdrawal?.paginate?.total / withdrawal?.paginate?.limit)
    );
  }, [withdrawal?.paginate]);

  // const [itemOffset, setItemOffset] = useState(0);
  if (!data) return;

  // const endOffset = itemOffset + range;

  // const currentItems = data.slice(itemOffset, endOffset);
  // const pageCount = Math.ceil(data.length / range);

  const currentItems = data;

  const handlePageClick = async (event) => {
    // const newOffset = (event.selected * range) % data.length;
    // setItemOffset(newOffset);
    await manageWithdrawal("get", {
      page: Number(event?.selected + 1),
    });
  };

  const headers = [
    { title: "S/N", field: "serialNumber" },
    { title: "ID", field: "id" },
    { title: "USER", field: "user" },
    { title: "BANK", field: "bank" },
    { title: "ACCOUNT NAME", field: "account_name" },
    { title: "AMOUNT", field: "amount" },
    { title: "DATE & TIME", field: "createdAt" },
  ];

  const tableData = currentItems.map((item, index) => ({
    serialNumber: index + 1,
    id: item?.item_id ? `${item?.item_id}` : "",
    user:
      item?.user?.firstName || item?.user?.lastName
        ? `${item?.user?.firstName} ${item?.user?.lastName}`
        : "",
    bank: item?.bank_name ? `${item?.bank_name}` : "",
    account_name: item?.reciever_name ? `${item?.reciever_name}` : "",
    amount: item?.amount
      ? `₦ ${numberWithCommas(
          Number(item?.amount - item?.transfer_fee).toFixed(2)
        )}`
      : "₦ 0",
    createdAt: moment(item?.createdAt).format("DD/MM/YYYY hh:mm A"),
  }));

  return (
    <div className="py-5">
      <div className="tw-flex tw-justify-between tw-items-center">
        <div className="tw-flex-1">
          {/* <MainRanger range={range} setRange={setRange} /> */}
        </div>
        <ExportButtons2
          headers={headers}
          data={tableData}
          fileName={`${params?.page} ${params?.id ? `(${params?.id})` : ""}`}
        />
      </div>
      <div className="row mx-0 my-2 py-3 bland">
        <div className="col my-auto text-uppercase fw-bold Lexend d-none d-md-flex fontReduce textTrunc">
          S/N
        </div>
        <div className="col my-auto text-uppercase fw-bold Lexend fontReduce textTrunc">
          ID
        </div>
        <div className="col my-auto text-uppercase fw-bold Lexend fontReduce textTrunc">
          Bank
        </div>
        <div className="col my-auto text-uppercase fw-bold Lexend fontReduce textTrunc">
          Account name
        </div>
        <div className="col my-auto text-uppercase fw-bold Lexend fontReduce textTrunc">
          Amount
        </div>
        <div className="col my-auto text-uppercase fw-bold Lexend d-none d-md-flex fontReduce textTrunc">
          Date & time
        </div>
      </div>
      {currentItems?.length === 0 ? (
        <EmptyComponent subtitle={"User withdrawal history is empty"} />
      ) : (
        currentItems?.map((it, i) => (
          <div key={i} className="row mx-0 py-3 bland2 myCursor border-bottom">
            <div className="col my-auto text-capitalize d-none d-md-flex fontReduce2 textTrunc">
              {i + 1}
            </div>
            <div className="col my-auto text-capitalize textTrunc textTrunc2 fontReduce2">
              {it?.item_id}
            </div>
            <div className="col my-auto text-capitalize textTrunc textTrunc2 fontReduce2">
              {it?.bank_name}
            </div>
            <div className="col my-auto text-capitalize textTrunc textTrunc2 fontReduce2">
              {it?.reciever_name}
            </div>
            <div className="col my-auto fontReduce2 textTrunc">
              {nairaSign}{" "}
              {it?.amount
                ? numberWithCommas(
                    Number(it?.amount - it?.transfer_fee).toFixed(2)
                  )
                : 0}
            </div>
            <div className="col my-auto d-none d-md-flex fontReduce2 textTrunc">
              {moment(it?.createdAt).format("DD/MM/YYYY HH:mm A")}
            </div>
          </div>
        ))
      )}
      <MainPaginate handlePageClick={handlePageClick} pageCount={pageCount} />
      <BottomTab state={data} paginate={withdrawal?.paginate} />
      {/* <LoadMore
        next={withdrawal?.paginate?.next}
        handleLoadMore={handleLoadMore}
        loading={loading}
      /> */}
    </div>
  );
};

const AllWithdrawalHistory = () => {
  let { withdrawal, manageWithdrawal, numberWithCommas, nairaSign } =
    useContext(GlobalState);

  let [data, setData] = useState(null);

  useEffect(() => {
    setData(withdrawal?.all_data);
  }, [withdrawal?.all_data]);

  useEffect(() => {
    manageWithdrawal("all-withdrawal");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let [loading, setLoading] = useState(false);
  // let handleLoadMore = async () => {
  //   setLoading(true);

  //   await manageWithdrawal("all-withdrawal", {
  //     page: Number(withdrawal?.all_paginate?.nextPage),
  //     // limit: Number(
  //     // 	withdrawal?.all_paginate?.nextPage * withdrawal?.all_paginate?.limit
  //     // ),
  //   });
  //   setLoading(false);
  // };

  // let [range, setRange] = useState(10);
  const params = useParams();

  const [pageCount, setPageCount] = useState(
    withdrawal?.all_paginate
      ? Math.ceil(
          withdrawal?.all_paginate?.total / withdrawal?.all_paginate?.limit
        )
      : 1
  );

  useEffect(() => {
    setPageCount(
      Math.ceil(
        withdrawal?.all_paginate?.total / withdrawal?.all_paginate?.limit
      )
    );
  }, [withdrawal?.all_paginate]);

  // const [itemOffset, setItemOffset] = useState(0);
  if (!data) return;

  // const endOffset = itemOffset + range;

  // const currentItems = data.slice(itemOffset, endOffset);
  // const pageCount = Math.ceil(data.length / range);

  const currentItems = data;

  const handlePageClick = async (event) => {
    // const newOffset = (event.selected * range) % data.length;
    // setItemOffset(newOffset);

    await manageWithdrawal("all-withdrawal", {
      page: Number(event?.selected + 1),
    });
  };

  const headers = [
    { title: "S/N", field: "serialNumber" },
    { title: "ID", field: "id" },
    { title: "USER", field: "user" },
    { title: "BANK", field: "bank" },
    { title: "ACCOUNT NAME", field: "account_name" },
    { title: "AMOUNT", field: "amount" },
    { title: "DATE & TIME", field: "createdAt" },
  ];

  const tableData = currentItems.map((item, index) => ({
    serialNumber: index + 1,
    id: `${item?.item_id}`,
    user: `${item?.user?.firstName} ${item?.user?.lastName}`,
    bank: `${item?.bank_name}`,
    account_name: `${item?.reciever_name}`,
    amount: it?.amount
      ? `₦ ${numberWithCommas(
          Number(it?.amount - it?.transfer_fee).toFixed(2)
        )}`
      : "₦ 0",
    createdAt: moment(item?.createdAt).format("DD/MM/YYYY hh:mm A"),
  }));

  return (
    <div className="py-5">
      <div className="tw-flex tw-justify-between tw-items-center">
        <div className="tw-flex-1">
          {/* <MainRanger range={range} setRange={setRange} /> */}
        </div>
        <ExportButtons2
          headers={headers}
          data={tableData}
          fileName={`${params?.page} ${params?.id ? `(${params?.id})` : ""}`}
        />
      </div>

      <div className="row mx-0 my-2 py-3 bland">
        <div className="col my-auto text-uppercase fw-bold Lexend d-none d-md-flex fontReduce textTrunc">
          S/N
        </div>
        <div className="col my-auto text-uppercase fw-bold Lexend fontReduce textTrunc">
          ID
        </div>
        <div className="col my-auto text-uppercase fw-bold Lexend fontReduce textTrunc">
          User
        </div>
        <div className="col my-auto text-uppercase fw-bold Lexend fontReduce textTrunc">
          Bank
        </div>
        <div className="col my-auto text-uppercase fw-bold Lexend fontReduce textTrunc">
          Account name
        </div>
        <div className="col my-auto text-uppercase fw-bold Lexend fontReduce textTrunc">
          Amount
        </div>
        <div className="col my-auto text-uppercase fw-bold Lexend d-none d-md-flex fontReduce textTrunc">
          Date & Time
        </div>
      </div>
      {currentItems?.length === 0 ? (
        <EmptyComponent subtitle={"User withdrawal history is empty"} />
      ) : (
        currentItems?.map((it, i) => (
          <div key={i} className="row mx-0 py-3 bland2 myCursor border-bottom">
            <div className="col my-auto text-capitalize d-none d-md-flex fontReduce2 textTrunc">
              {i + 1}
            </div>
            <div className="col my-auto text-capitalize textTrunc textTrunc2 fontReduce2">
              {it?.item_id}
            </div>
            <div className="col my-auto text-capitalize textTrunc textTrunc2 fontReduce2">
              {it?.user?.firstName} {it?.user?.lastName}
            </div>
            <div className="col my-auto text-capitalize textTrunc textTrunc2 fontReduce2">
              {it?.bank_name}
            </div>
            <div className="col my-auto text-capitalize textTrunc textTrunc2 fontReduce2">
              {it?.reciever_name}
            </div>
            <div className="col my-auto fontReduce2 textTrunc">
              {nairaSign}{" "}
              {it?.amount
                ? numberWithCommas(
                    Number(it?.amount - it?.transfer_fee).toFixed(2)
                  )
                : 0}
            </div>
            <div className="col my-auto d-none d-md-flex fontReduce2 textTrunc">
              {moment(it?.createdAt).format("DD/MM/YYYY HH:mm A")}
            </div>
          </div>
        ))
      )}
      <MainPaginate handlePageClick={handlePageClick} pageCount={pageCount} />
      <BottomTab state={data} paginate={withdrawal?.all_paginate} />
      {/* <LoadMore
        next={withdrawal?.all_paginate?.next}
        handleLoadMore={handleLoadMore}
        loading={loading}
      /> */}
    </div>
  );
};
