import React, { useState, useContext, useEffect, useRef } from "react";
import { Container } from "reactstrap";
import { Buttons, EmptyComponent } from "../../Utils";
import { GlobalState } from "../../Data/Context";
import LoadMore, { BottomTab } from "../LoadMore";
import {
  TransactionDetails,
  TransactionToppers,
  NewPaginate,
} from "../Transactions";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useReactToPrint } from "react-to-print";

const MainBiz = () => {
  let navigate = useNavigate();

  let { setStateName } = useContext(GlobalState),
    [active, setActive] = useState(0);
  useEffect(() => {
    setStateName("biz verification history");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="bg-white aboutScreen">
      <Container className="py-5">
        <Buttons
          title={"find biz"}
          css="btn-primary1 text-capitalize py-3 px-4 px-lg-5"
          width={"w-25 w25"}
          onClick={() => navigate("/products/biz/verify")}
          style={{ borderRadius: "30px" }}
        />
        <TransactionToppers
          title={"biz"}
          active={active}
          setActive={setActive}
        />
        {active === 1 ? (
          <AllMainBizHistory active={active} />
        ) : (
          <MainBizHistory active={active} />
        )}
      </Container>
    </div>
  );
};

export default MainBiz;

const MainBizHistory = ({ active }) => {
  let { biz, getServicesHistory, getReload } = useContext(GlobalState);

  let [data, setData] = useState(null),
    [search, setSearch] = useState(""),
    [thisData, setThisData] = useState(null);

  useEffect(() => {
    if (biz.isFound) {
      setData(biz.mainSearch);
    } else setData(biz.biz);
  }, [biz.biz, biz.isFound, biz.mainSearch]);

  useEffect(() => {
    if (search) {
      document.getElementById("Search").addEventListener("search", () => {
        getReload();
      });
      let handleSubmit = async () => {
        if (!search) return;

        await getServicesHistory("biz", {
          search,
        });
      };
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    getReload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getServicesHistory("biz");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let [loading, setLoading] = useState(false);
  //   let handleLoadMore = async () => {
  //     setLoading(true);

  //     await getServicesHistory("biz", {
  //       page: Number(biz?.paginate?.nextPage),
  //       // limit: Number(biz?.paginate?.nextPage * biz?.paginate?.limit),
  //     });
  //     setLoading(false);
  //   };

  if (!data) return;
  // console.log({ data });

  return (
    <div className="py-5">
      <div className="w-50 w50 mb-3">
        <input
          type="search"
          name="search"
          id="Search"
          className="form-control w-100 py-3 borderColor2"
          placeholder="Type here to search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <NewPaginate
        state={data}
        setState={setData}
        setThisData={setThisData}
        type={"biz"}
        criteria={{
          active,
          search,
        }}
        my="myall"
      />
      <TransactionDetails
        thisData={thisData}
        setThisData={setThisData}
        type={"biz"}
        criteria={
          {
            // id: params?.step,
          }
        }
      />
      <BottomTab state={data} paginate={biz?.paginate} />
      {/* <LoadMore
        next={biz?.paginate?.next}
        handleLoadMore={handleLoadMore}
        loading={loading}
      /> */}
    </div>
  );
};

const AllMainBizHistory = ({ active }) => {
  let { biz, getAllServicesHistory, getReload } = useContext(GlobalState);

  let [data, setData] = useState(null),
    [search, setSearch] = useState(""),
    [thisData, setThisData] = useState(null);

  useEffect(() => {
    if (biz.all_isFound) {
      setData(biz.all_mainSearch);
    } else setData(biz.all_biz);
  }, [biz.all_biz, biz.all_isFound, biz.all_mainSearch]);

  useEffect(() => {
    if (search) {
      document.getElementById("Search").addEventListener("search", () => {
        getReload();
      });
      let handleSubmit = async () => {
        if (!search) return;

        await getAllServicesHistory("biz", {
          search,
        });
      };
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    getReload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getAllServicesHistory("biz");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let [loading, setLoading] = useState(false);
  //   let handleLoadMore = async () => {
  //     setLoading(true);

  //     await getAllServicesHistory("biz", {
  //       page: Number(biz?.all_paginate?.nextPage),
  //       // limit: Number(biz?.all_paginate?.nextPage * biz?.all_paginate?.limit),
  //     });
  //     setLoading(false);
  //   };

  if (!data) return;
  // console.log({ data });

  return (
    <div className="py-5">
      <div className="w-50 w50 mb-3">
        <input
          type="search"
          name="search"
          id="Search"
          className="form-control w-100 py-3 borderColor2"
          placeholder="Type here to search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <NewPaginate
        state={data}
        setState={setData}
        setThisData={setThisData}
        type={"biz"}
        criteria={{
          active,
          search, // id: params?.step,
        }}
      />
      <TransactionDetails
        thisData={thisData}
        setThisData={setThisData}
        type={"biz"}
        criteria={
          {
            // id: params?.step,
          }
        }
      />
      <BottomTab state={data} paginate={biz?.all_paginate} />
      {/* <LoadMore
        next={biz?.all_paginate?.next}
        handleLoadMore={handleLoadMore}
        loading={loading}
      /> */}
    </div>
  );
};

export const BizFindPage = () => {
  let {
    setStateName,
    biz,
    buyServices,
    returnErrors,
    usecase,
    numberWithCommas,
  } = useContext(GlobalState);
  useEffect(() => {
    setStateName("Verify biz name");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let [state, setState] = useState(""),
    [loading, setLoading] = useState(false),
    [submit, setSubmit] = useState(false),
    handleSubmit = async (e) => {
      e?.preventDefault();
      if (!state)
        return returnErrors({
          error: [{ msg: "Business name is required", param: "name" }],
        });
      setLoading(true);
      await buyServices("biz", { name: state });
      setLoading(false);
      setSubmit(true);
    },
    navigate = useNavigate();

  let ref = useRef();
  const handlePrint = useReactToPrint({
    content: () => ref.current,
    documentTitle: `${
      process.env.REACT_APP_NAME
    }-${biz?.biz?.[0]?.type?.toUpperCase()}-${moment(
      biz?.biz?.[0]?.createdAt
    ).format("dddd, L")}`,
    bodyClass: "p-2",
  });

  useEffect(() => {
    if (biz?.isAdded && submit) {
      setSubmit(false);
      setState("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [biz?.isAdded, submit]);

  return (
    <div className="bg-white aboutScreen">
      <Container className="py-5">
        <Buttons
          title={"View History"}
          css="btn-primary1 text-capitalize py-3 px-4 px-lg-5 ms-auto my-4"
          width={"w-25 w25"}
          onClick={() => navigate("/products/biz")}
          style={{ borderRadius: "30px" }}
        />
        <div className="d-flex flex-column justify-content-center">
          <form className="w-50 w50 mx-auto" onSubmit={handleSubmit}>
            <p className="Lexend">
              Please verify people's business before engaging in any deals for
              just ₦
              {numberWithCommas(
                Number(usecase?.usecase?.bizUser || 100).toFixed(2)
              )}
            </p>
            <p className="Lexend">
              To do so, kindly fill out the form below with the business name,
              and you will receive an instant response.
            </p>
            <input
              type="text"
              value={state}
              onChange={(e) => setState(e.target.value)}
              className="py-3 form-control"
              placeholder="Type business name here"
            />
            <Buttons
              title={"find biz"}
              css="btn-primary1 text-capitalize py-3 px-4 px-lg-5"
              width={"w-25 w25 mx-auto d-block mt-3"}
              onClick={handleSubmit}
              loading={loading}
              style={{ borderRadius: "30px" }}
            />
          </form>
        </div>
        <div className="py-3 py-md-5" ref={ref}>
          {biz?.isAdded && (
            <>
              <div className="row mx-0 py-3 bland">
                <div className="col textTrunc my-auto text-uppercase fontReduce2 fw-bold Lexend">
                  s/n
                </div>
                <div className="col textTrunc my-auto text-uppercase fontReduce2 fw-bold Lexend d-none d-md-flex">
                  rc Number
                </div>
                <div className="col textTrunc my-auto text-uppercase fontReduce2 fw-bold Lexend">
                  Approved Name
                </div>
                <div className="col textTrunc my-auto text-uppercase fontReduce2 fw-bold Lexend">
                  address
                </div>
                <div className="col textTrunc my-auto text-uppercase fontReduce2 fw-bold Lexend">
                  email
                </div>
                <div className="col my-auto text-uppercase fontReduce2 fw-bold Lexend d-none d-md-flex">
                  registration Date
                </div>
                <div className="col textTrunc my-auto text-uppercase fontReduce2 fw-bold Lexend">
                  company Status
                </div>
                <div className="col textTrunc my-auto text-uppercase fontReduce2 fw-bold Lexend">
                  status
                </div>
              </div>
              {biz?.biz?.[0]?.properties?.result?.length === 0 ? (
                <EmptyComponent
                  subtitle={"Business vierification result empty"}
                />
              ) : (
                biz?.biz?.[0]?.properties?.result?.map((item, i) => (
                  <div
                    className="row mx-0 bland2 border-bottom page-break"
                    key={i}
                  >
                    <div className="col my-auto fontReduce2 textTrunc py-3 py-md-4">
                      {i + 1}
                    </div>
                    <div className="col my-auto fontReduce2 textTrunc py-3 py-md-4">
                      {item?.rcNumber}
                    </div>
                    <div className="col my-auto fontReduce2 textTrunc py-3 py-md-4">
                      {item?.approvedName}
                    </div>
                    <div className="col my-auto fontReduce2 textTrunc py-3 py-md-4 textTrunc4">
                      {item?.address}
                    </div>
                    <div className="col my-auto fontReduce2 textTrunc py-3 py-md-4">
                      {item?.email}
                    </div>
                    <div className="col my-auto d-none d-md-flex textTrunc py-3 py-md-4">
                      {moment(item?.registrationDate).format(
                        "DD/MM/YYYY HH:mm A"
                      )}
                    </div>
                    <div className="col my-auto fontReduce2 textTrunc py-3 py-md-4">
                      {item?.companyStatus}
                    </div>
                    <div className="col my-auto fontReduce2 textTrunc py-3 py-md-4">
                      {item?.status}
                    </div>
                  </div>
                ))
              )}
            </>
          )}
        </div>
        {biz?.isAdded && biz?.biz?.[0]?.properties?.result?.length > 0 && (
          <div className="ms-auto d-flex align-items-center py-2">
            <button
              className="btn btn-primary1 d-block ms-auto"
              onClick={handlePrint}
            >
              Print List
            </button>
          </div>
        )}
      </Container>
    </div>
  );
};
