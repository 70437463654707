import React, { useContext } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  Sidebar,
  DefaultHeader,
  SideHeader,
  ModalComponents,
} from "./Components";
import { GlobalState } from "./Data/Context";
import PageRender from "./PageRender";
import Home from "./Screens/home";
import Home2 from "./Pages/home";
import gif from "./Assets/icons8-ok.gif";
import gif2 from "./Assets/icons8-cancel.gif";
// import SocketClient from "./SocketClient";

const Routers = () => {
  const { auth, success, restoreMsg, errors, clearErrors, logoutUser } =
      useContext(GlobalState),
    navigate = useNavigate();

  let handleLogOut = async (e) => {
      e?.preventDefault();
      logoutUser();
      navigate("/");
    },
    handleCloseError = () => {
      clearErrors();
      if (
        errors?.error?.error?.[0]?.msg
          ?.toLowerCase()
          ?.includes("insufficient wallet fund")
      ) {
        navigate("/wallets");
      }
      if (
        errors?.error?.error?.[0]?.msg
          ?.toLowerCase()
          ?.includes("session timeout, please login again")
      ) {
        handleLogOut();
      }
      if (
        errors?.error?.error?.[0]?.msg
          ?.toLowerCase()
          ?.includes("multiple logins detected. re-login to continue.")
      ) {
        handleLogOut();
      }
    };

  return (
    <>
      <ToastContainer autoClose={false} />
      {auth?.user ? (
        <>
          <Sidebar />
          <SideHeader noLogo />
          {/* <SocketClient /> */}
        </>
      ) : (
        <SideHeader />
      )}
      <div className={auth?.user ? "home" : ""}>
        {auth?.user ? <DefaultHeader /> : <></>}
        <Routes>
          <Route path="/" element={auth?.user ? <Home2 /> : <Home />} />
          <Route path="/:page" element={<PageRender />} />
          <Route path="/:page/:id" element={<PageRender />} />
          <Route path="/:page/:id/:step" element={<PageRender />} />
        </Routes>
      </div>
      <ModalComponents
        isOpen={success?.msg ? true : false}
        title="Success"
        size={"sm"}
        success="text-success text-succcess2"
        borderNone={"borderNone"}
        toggle={() => restoreMsg()}
      >
        <div className="downH2 d-flex flex-column">
          <div className="mx-auto">
            <img src={gif} alt="Gif" className="img-fluid" />
          </div>
          <p className="fw-bold Lexend text-center w-100">{success?.msg}</p>
          <button
            onClick={() => restoreMsg()}
            className="btn btn-success2 py-2 py-md-3 text-capitalize mx-auto my-3 px-3 px-md-5 mt-auto"
          >
            close
          </button>
        </div>
      </ModalComponents>
      <ModalComponents
        isOpen={errors?.error?.error?.length > 0}
        title="Error"
        size={"sm"}
        success="text-danger text-danger2"
        borderNone={"borderNone"}
        toggle={handleCloseError}
      >
        <div className="downH2 d-flex flex-column">
          <div className="mx-auto mb-3">
            <img src={gif2} alt="Gif" className="img-fluid" />
          </div>
          {errors?.error?.error?.map((item, i) => (
            <p key={i} className="fw-bold Lexend text-center w-100">
              <span className="fontInherit me-2">
                {errors?.error?.error?.length > 1 && <>{i + 1}.</>}
              </span>{" "}
              {item?.msg}
            </p>
          ))}
          <button
            onClick={handleCloseError}
            className="btn btn-primary1 py-2 py-md-3 text-capitalize mx-auto my-3 px-3 px-md-5 mt-auto"
          >
            close
          </button>
        </div>
      </ModalComponents>
    </>
  );
};

export default Routers;
