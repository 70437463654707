import React, { useContext, useEffect, useState } from "react";
import { Container } from "reactstrap";
import LoadMore, { BottomTab } from "../../Components/LoadMore";
import { GlobalState } from "../../Data/Context";
import { Buttons, EmptyComponent } from "../../Utils";
import moment from "moment";
import { MainPaginate, MainRanger } from "../../Components/Transactions";
import { useNavigate, useParams } from "react-router-dom";
import { ExportButtons2 } from "../../Utils/Export Functions/ExportButtons";

const Activities = () => {
  let { setStateName, activity, getReload, getActivity } =
    useContext(GlobalState);
  useEffect(() => {
    setStateName("Activity");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let [loading, setLoading] = useState(false),
    [search, setSearch] = useState(""),
    [state, setState] = useState(null),
    navigate = useNavigate();

  useEffect(() => {
    if (search) {
      document.getElementById("Search").addEventListener("search", () => {
        getReload();
      });
      let handleSubmit = async () => {
        if (!search) return;

        await getActivity({
          search,
        });
      };
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (activity.isFound) {
      setState(activity.mainSearch);
    } else setState(activity.data);
  }, [activity.data, activity.isFound, activity.mainSearch]);

  useEffect(() => {
    getReload();
    getActivity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // let handleLoadMore = async () => {
  //   setLoading(true);

  //   if (search) {
  //     await getActivity({
  //       page: Number(activity?.paginate?.nextPage),
  //       // limit: Number(activity?.paginate?.nextPage * activity?.paginate?.limit),
  //       search,
  //     });
  //   } else {
  //     await getActivity({
  //       page: Number(activity?.paginate?.nextPage),
  //       // limit: Number(activity?.paginate?.nextPage * activity?.paginate?.limit),
  //     });
  //   }
  //   setLoading(false);
  // };

  // let [range, setRange] = useState(10);
  const params = useParams();

  const [pageCount, setPageCount] = useState(
    activity?.paginate
      ? Math.ceil(activity?.paginate?.total / activity?.paginate?.limit)
      : 1
  );

  useEffect(() => {
    if (activity?.search_paginate) {
      setPageCount(
        Math.ceil(
          activity?.search_paginate?.total / activity?.search_paginate?.limit
        )
      );
    } else {
      setPageCount(
        Math.ceil(activity?.paginate?.total / activity?.paginate?.limit)
      );
    }
  }, [activity?.search_paginate, activity?.paginate]);

  // const [itemOffset, setItemOffset] = useState(0);
  if (!state) return;

  // const endOffset = itemOffset + range;

  // const currentItems = state.slice(itemOffset, endOffset);
  // const pageCount = Math.ceil(state.length / range);

  const currentItems = state;

  const handlePageClick = async (event) => {
    // const newOffset = (event.selected * range) % state.length;
    // setItemOffset(newOffset);

    if (search) {
      await getActivity({
        page: Number(event?.selected + 1),
        search,
      });
    } else {
      await getActivity({
        page: Number(event?.selected + 1),
      });
    }
  };

  const headers = [
    { title: "S/N", field: "serialNumber" },
    { title: "User", field: "userName" },
    { title: "Action", field: "action" },
    { title: "Date & Time", field: "createdAt" },
  ];

  const data = currentItems.map((item, index) => ({
    serialNumber: index + 1,
    userName:
      item?.user?.firstName || item?.user?.lastName
        ? `${item?.user?.firstName} ${item?.user?.lastName}`
        : "",
    action: item?.message ? `${item?.message}` : "",
    createdAt: moment(item?.createdAt).format("DD/MM/YYYY hh:mm A"),
  }));

  return (
    <div className="bg-white aboutScreen">
      <Container className="py-5">
        <div className="d-flex justify-content-end">
          <Buttons
            title={"view General Activities"}
            css="btn-primary1 text-capitalize py-3 my-4 px-5 mx-2"
            width={"w-auto"}
            onClick={() => {
              navigate("/activities/general");
            }}
            style={{ borderRadius: "30px" }}
          />
        </div>
        <h4 className="Lexend">All activities</h4>
        <div className="w-50 w50 mb-3">
          <input
            type="search"
            name="search"
            id="Search"
            className="form-control w-100 py-3 borderColor2"
            placeholder="Type here to search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div className="py-3 py-md-5">
          <div className="tw-flex tw-justify-between tw-items-center">
            <div className="tw-flex-1">
              {/* <MainRanger range={range} setRange={setRange} /> */}
            </div>
            <ExportButtons2
              headers={headers}
              data={data}
              fileName={`${params?.page} ${
                params?.id ? `(${params?.id})` : ""
              }`}
            />
          </div>
          <div className="bland row mx-0 py-3 px-0 text-capitalize Lexend fw-bold">
            <div className="col textTrunc Lexend d-none d-md-flex">S/N</div>
            <div className="col textTrunc Lexend">User</div>
            <div className="col textTrunc Lexend">Action</div>
            <div className="col textTrunc Lexend">Date&time</div>
          </div>
          {currentItems?.length === 0 ? (
            <EmptyComponent subtitle={"Activity is empty"} />
          ) : (
            currentItems?.map((it, i) => (
              <div key={i} className="row mx-0 py-3 border-bottom myCursor">
                <div className="col my-auto text-capitalize fontReduce2 textTrunc py-3 py-md-4 d-none d-md-flex">
                  {i + 1}
                </div>
                <div className="col my-auto text-capitalize fontReduce2 textTrunc py-3 py-md-4 textTrunc2">
                  {it?.user?.firstName} {it?.user?.lastName}
                </div>
                <div className="col my-auto fontReduce2 textTrunc py-3 py-md-4 textTrunc4 textTrunc5">
                  {it?.message}
                </div>
                <div className="col my-auto fontReduce2 textTrunc py-3 py-md-4 textTrunc4">
                  {moment(it?.createdAt).format("DD/MM/YYYY hh:mm:ss A")}
                </div>
              </div>
            ))
          )}
          <MainPaginate
            handlePageClick={handlePageClick}
            pageCount={pageCount}
          />
          <BottomTab state={state} paginate={activity?.paginate} />
          {/* <LoadMore
            next={activity?.paginate?.next}
            handleLoadMore={handleLoadMore}
            loading={loading}
          /> */}
        </div>
      </Container>
    </div>
  );
};

export default Activities;
