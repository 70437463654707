import React, { useContext, useEffect, useState } from "react";
import { Container } from "reactstrap";
// import { ThreeBoxBar } from "../../Components/Users";
import icon1 from "../../Assets/Analythics.png";
import icon2 from "../../Assets/Сoding.png";
import icon3 from "../../Assets/Money.png";
import icon4 from "../../Assets/Hands Give.png";
import { GlobalState } from "../../Data/Context";
import { Buttons, EmptyComponent } from "../../Utils";
// import user from "../../Assets/avatar3.png";
import moment from "moment";
import { BiCog, BiCopy } from "react-icons/bi";
import { ModalComponents } from "../../Components";
import { useNavigate, useParams } from "react-router-dom";
import { FourBoxBar } from "../../Components/Dashboard";
import { MainPaginate, MainRanger } from "../../Components/Transactions";
import LoadMore, { BottomTab } from "../../Components/LoadMore";
import { AvatarImg } from "../../Components/DefaultHeader";
import { toast } from "react-toastify";
import { ExportButtons2 } from "../../Utils/Export Functions/ExportButtons";

const UpgradeMain = () => {
  let {
    setStateName,
    upgrade,
    numberWithCommas,
    auth,
    permission,
    nairaSignNeutral,
    manageUpgrade,
  } = useContext(GlobalState);
  useEffect(() => {
    setStateName("upgrade list");
    manageUpgrade();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let upgradeArr = [
      {
        icon: icon1,
        name: "total request",
        number: upgrade?.paginate?.total
          ? numberWithCommas(upgrade?.paginate?.total)
          : 0,
        color: "linear-gradient(90.18deg, #84C7DB -52.19%, #377FB6 81.92%)",
      },
      {
        icon: icon2,
        name: "approved request",
        number: numberWithCommas(
          upgrade?.approved ||
            upgrade?.upgrade?.filter(
              (it) => it?.statusText?.toLowerCase() === "approved"
            )?.length ||
            0
        ),
        color: "linear-gradient(90deg, #D88ADA 16.14%, #CA64FB 101.45%)",
      },
      {
        icon: icon3,
        name: "declined request",
        number: numberWithCommas(
          upgrade?.declined ||
            upgrade?.upgrade?.filter(
              (it) => it?.statusText?.toLowerCase() === "declined"
            )?.length ||
            0
        ),
        color: "linear-gradient(90deg, #F45F83 16.14%, #9E1A2A 101.45%)",
      },
      {
        icon: icon4,
        name: "Application fee",
        number: `${nairaSignNeutral}${numberWithCommas(upgrade?.fee || 0)}`,
        color: "linear-gradient(90.18deg, #6CB731 -52.19%, #0F5A16 81.92%)",
      },
    ],
    navigate = useNavigate();

  useEffect(() => {
    if (auth?.user?.privilege !== "superadmin") {
      let findTitle = "viewmanageupgrade";

      let findPermit = permission?.data?.find(
        (item) =>
          item?.slug === findTitle && item?.user?.includes(auth?.user?._id)
      );
      if (!findPermit) {
        navigate(-1);
      }
    }
  }, [auth?.user, permission?.data, navigate]);

  return (
    <div className="bg-white aboutScreen">
      <Container>
        <FourBoxBar list={upgradeArr} css={"col-md-3"} />
        <UserListOne />
      </Container>
    </div>
  );
};

export default UpgradeMain;

export const UserListOne = () => {
  const {
    upgrade,
    manageUpgrade,
    getReload,
    numberWithCommas,
    nairaSign,
    auth,
  } = useContext(GlobalState);
  let [data, setData] = useState(null),
    [isOpen, setIsOpen] = useState(false),
    [loading, setLoading] = useState(false),
    [loading2, setLoading2] = useState(false),
    [submit, setSubmit] = useState(false),
    [mainUser, setMainUser] = useState(null),
    [search, setSearch] = useState(null),
    [reason, setReason] = useState(""),
    toggle = () => {
      if (isOpen) {
        setMainUser(null);
      }
      setIsOpen(!isOpen);
    };

  useEffect(() => {
    setData(upgrade?.upgrade);
  }, [upgrade?.upgrade]);

  useEffect(() => {
    if (submit && upgrade?.isUpdated) {
      setIsOpen(false);
      setMainUser(null);
      setSubmit(false);
      setReason("");
    }
  }, [submit, upgrade?.isUpdated]);

  useEffect(() => {
    if (search) {
      document.getElementById("Search").addEventListener("search", () => {
        getReload();
      });
      let handleSubmit = async () => {
        if (!search) return;

        await manageUpgrade("", "", {
          search,
        });
      };
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (upgrade.isFound) {
      setData(upgrade.mainSearch);
    } else setData(upgrade?.upgrade);
  }, [upgrade?.upgrade, upgrade?.mainSearch, upgrade?.isFound]);

  // let handleLoadMore = async () => {
  //   setLoading(true);

  //   await manageUpgrade("", "", {
  //     limit: search
  //       ? Number(upgrade?.search_paginate?.nextPage * upgrade?.paginate?.limit)
  //       : Number(upgrade?.paginate?.nextPage * upgrade?.paginate?.limit),
  //     search,
  //   });
  //   setLoading(false);
  // };

  const [pageCount, setPageCount] = useState(
    upgrade?.paginate
      ? Math.ceil(upgrade?.paginate?.total / upgrade?.paginate?.limit)
      : 1
  );

  useEffect(() => {
    if (upgrade?.search_paginate) {
      setPageCount(
        Math.ceil(
          upgrade?.search_paginate?.total / upgrade?.search_paginate?.limit
        )
      );
    } else {
      setPageCount(
        Math.ceil(upgrade?.paginate?.total / upgrade?.paginate?.limit)
      );
    }
  }, [upgrade?.search_paginate, upgrade?.paginate]);

  // let [range, setRange] = useState(10);
  const params = useParams();

  // const [itemOffset, setItemOffset] = useState(0);
  // const endOffset = itemOffset + range;

  if (!data) return;

  // const currentItems = data.slice(itemOffset, endOffset);
  const currentItems = data;

  const handlePageClick = async (event) => {
    // const newOffset = (event.selected * range) % data.length;
    // setItemOffset(newOffset);
    // setLoading(true);

    await manageUpgrade("", "", {
      page: Number(event?.selected + 1),
      search,
    });
    // setLoading(false);
  };

  let handleSubmit = (type) => async () => {
    if (type === "decline") if (!reason) return;

    if (type === "approve") setLoading(true);
    else setLoading2(true);

    await manageUpgrade({ ...mainUser, reason }, type);
    if (type === "approve") setLoading2(false);
    else setLoading2(false);

    setSubmit(true);
  };

  const headers = [
    { title: "NAME", field: "userName" },
    { title: "EMAIL", field: "email" },
    { title: "PRIVILEGE", field: "privilege" },
    { title: "APPLIED FOR", field: "applied_for" },
    { title: "STATUS", field: "status" },
    { title: "DATE & TIME", field: "createdAt" },
  ];

  const tableData = currentItems.map((item) => ({
    userName:
      item?.user?.lastName || item?.user?.firstName
        ? `${item?.user?.lastName} ${item?.user?.firstName}`
        : "",
    email: item?.user?.email ? `${item?.user?.email}` : "",
    privilege:
      item?.accountPrivilege || item?.user?.privilege
        ? `${item?.accountPrivilege || item?.user?.privilege}`
        : "",
    applied_for: item?.privilege ? `${item?.privilege}` : "",
    status: item?.statusText ? `${item?.statusText}` : "",
    createdAt: moment(item?.createdAt).format("DD/MM/YYYY hh:mm A"),
  }));

  return (
    <div className="pb-5 my-5">
      <div className="w-50 w50">
        <input
          type="search"
          name="search"
          id="Search"
          className="form-control w-100 py-3 borderColor2"
          placeholder="Type here to search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div className="tw-flex tw-justify-between tw-items-center">
        <div className="tw-flex-1">
          {/* <MainRanger range={range} setRange={setRange} /> */}
        </div>
        <ExportButtons2
          headers={headers}
          data={tableData}
          fileName={`${params?.page} ${params?.id && `(${params?.id})`}`}
        />
      </div>
      ;
      <div className="bland row mx-0 py-3 text-capitalize">
        <div className="col textTrunc text-uppercase fontReduce fw-bold Lexend">
          Name
        </div>
        <div className="col textTrunc text-uppercase fontReduce fw-bold Lexend">
          Email
        </div>
        <div className="col textTrunc text-uppercase fontReduce fw-bold Lexend">
          privilege
        </div>
        <div className="col textTrunc text-uppercase fontReduce fw-bold Lexend">
          Applied for
        </div>
        <div className="col textTrunc text-uppercase fontReduce fw-bold Lexend">
          status
        </div>
        <div className="col textTrunc text-uppercase fontReduce fw-bold Lexend">
          date&time
        </div>
        <div className="col textTrunc text-uppercase fontReduce fw-bold Lexend text-center">
          Action
        </div>
      </div>
      <div className="bland2 row mx-0 my-0">
        {currentItems?.length === 0 ? (
          <EmptyComponent subtitle={"Request list is empty"} />
        ) : (
          currentItems?.map((item, index) => (
            <div key={index} className="row mx-0 px-3 border-bottom">
              <div className="col fontReduce2 textTrunc py-3 py-md-4">
                <div className="d-flex align-items-center w-100">
                  {/* <img
										src={item?.user?.avatar ? item?.user?.avatar?.url : user}
										alt="User"
										className="img-fluid rounded-circle d-none d-md-flex imgFluid"
										style={{
											height: "3rem",
											width: "3rem",
										}}
									/> */}
                  <AvatarImg user={item?.user} />
                  <span className="fontInherit my-0 ps-0 ps-md-1 textTrunc textTrunc2 fontReduce w-100">
                    {item?.user?.lastName} {item?.user?.firstName}
                  </span>
                </div>
              </div>
              <div className="col fontReduce2 textTrunc py-3 py-md-4 ">
                {item?.user?.email}
              </div>
              <div className="col fontReduce2 textTrunc py-3 py-md-4  text-capitalize">
                {item?.accountPrivilege || item?.user?.privilege}
              </div>
              <div className="col fontReduce2 textTrunc py-3 py-md-4  text-capitalize">
                {item?.privilege}
              </div>
              {/* <div className="col fontReduce2 textTrunc py-3 py-md-4  text-capitalize">
								{item?.statusText}
							</div> */}
              <div
                className={`col textTrunc fontReduce2 text-uppercase py-3 py-md-4 ${
                  item?.statusText?.toLowerCase() === "approved"
                    ? "text-succcess2"
                    : item?.statusText?.toLowerCase() === "pending"
                    ? "text-dark"
                    : "text-danger2"
                }`}
              >
                {item?.statusText}
              </div>
              <div className="col fontReduce2 textTrunc py-3 py-md-4 ">
                {moment(item?.createdAt).format("DD/MM/YYYY hh:mm A")}
              </div>
              <div
                className="col fontReduce2 textTrunc py-3 py-md-4 myCursor text-center d-flex justify-content-center align-items-center"
                onClick={() => {
                  setMainUser(item);
                  toggle();
                }}
              >
                <BiCog className="iconDash" />
              </div>
            </div>
          ))
        )}
      </div>
      <MainPaginate pageCount={pageCount} handlePageClick={handlePageClick} />
      <BottomTab
        state={data}
        paginate={
          upgrade?.isFound ? upgrade?.search_paginate : upgrade?.paginate
        }
      />
      {/* <LoadMore
        next={
          upgrade?.isFound
            ? upgrade?.search_paginate?.nexh
            : upgrade?.paginate?.next
        }
        handleLoadMore={handleLoadMore}
        loading={loading}
      /> */}
      <ModalComponents
        title={`Manage ${mainUser?.user?.lastName} ${mainUser?.user?.firstName}'s Request`}
        isOpen={isOpen}
        back={toggle}
      >
        <p className="text-capitalize border-bottom d-flex justify-content-between">
          <span>Name: </span>
          <span className="fontInherit Lexend text-uppercase">
            {mainUser?.user?.lastName} {mainUser?.user?.firstName}
          </span>{" "}
        </p>
        <p className="text-capitalize border-bottom d-flex justify-content-between">
          <span>Account Type: </span>
          <span className="fontInherit Lexend text-uppercase">
            {mainUser?.accountPrivilege || mainUser?.user?.privilege}{" "}
          </span>{" "}
        </p>
        <p className="text-capitalize border-bottom d-flex justify-content-between">
          <span>Request Account Type: </span>
          <span className="fontInherit Lexend text-uppercase">
            {mainUser?.privilege}{" "}
          </span>{" "}
        </p>
        <p className="text-capitalize border-bottom d-flex justify-content-between">
          <span>Business Name: </span>
          <span className="fontInherit Lexend text-uppercase">
            {mainUser?.businessName}{" "}
          </span>{" "}
        </p>
        <p className="text-capitalize border-bottom d-flex justify-content-between">
          <span>State of Residence: </span>
          <span className="fontInherit Lexend text-uppercase">
            {mainUser?.stateOfResidence}{" "}
          </span>{" "}
        </p>
        <p className="text-capitalize border-bottom d-flex justify-content-between">
          <span>NIN Number: </span>
          <span
            className="fontInherit Lexend force-d-flex myCursor"
            onClick={
              mainUser?.ninNumber
                ? () => {
                    navigator.clipboard.writeText(mainUser?.ninNumber).then(
                      () => {
                        toast.info("Copied", { autoClose: 2000 });
                      },
                      (err) => {
                        toast.warn(`Could not copy: ${err}`, {
                          autoClose: 2000,
                        });
                      }
                    );
                  }
                : null
            }
          >
            {mainUser?.ninNumber}
            <BiCopy />
          </span>{" "}
        </p>
        {mainUser?.websiteURL && (
          <p className="text-capitalize border-bottom d-flex justify-content-between">
            <span>Website URL: </span>
            <span
              className="fontInherit Lexend force-d-flex myCursor"
              onClick={
                mainUser?.websiteURL
                  ? () => {
                      navigator.clipboard.writeText(mainUser?.websiteURL).then(
                        () => {
                          toast.info("Copied", { autoClose: 2000 });
                        },
                        (err) => {
                          toast.warn(`Could not copy: ${err}`, {
                            autoClose: 2000,
                          });
                        }
                      );
                    }
                  : null
              }
            >
              {mainUser?.websiteURL}
              <BiCopy />
            </span>
          </p>
        )}
        {mainUser?.channel ? (
          <p className="text-capitalize border-bottom d-flex justify-content-between">
            <span>Channel: </span>
            <span className="fontInherit Lexend text-uppercase">
              {mainUser?.channel}
            </span>{" "}
          </p>
        ) : null}
        {mainUser?.amount && Number(mainUser?.amount) > 0 ? (
          <p className="text-capitalize border-bottom d-flex justify-content-between">
            <span>Amount: </span>
            <span className="fontInherit Lexend text-uppercase">
              {nairaSign}{" "}
              {numberWithCommas(Number(mainUser?.amount || 0).toFixed(2))}
            </span>{" "}
          </p>
        ) : null}
        {auth?.user?.isAdmin && mainUser?.authorizer && (
          <>
            <p className="border-bottom d-flex justify-content-between">
              <span className="text-capitalize">Admin: </span>
              <span>
                <span className="fontInherit Lexend d-block text-capitalize">
                  {mainUser?.authorizer?.lastName}{" "}
                  {mainUser?.authorizer?.firstName}
                </span>{" "}
                <span className="fontInherit Lexend d-block">
                  {mainUser?.authorizer?.telephone}
                </span>{" "}
                <span className="fontInherit Lexend d-block">
                  {mainUser?.authorizer?.email}
                </span>{" "}
                {mainUser?.authorizer?.privilege && (
                  <span className="fontInherit Lexend d-block tw-uppercase">
                    {mainUser?.authorizer?.privilege}
                  </span>
                )}
              </span>
            </p>
          </>
        )}
        {!mainUser?.status && (
          <>
            <div className="mb-3">
              <label htmlFor="Decline">If decline, Give reason</label>
              <textarea
                name="Decline"
                className="form-control py-3"
                placeholder="Give reason for the decline"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                style={{
                  resize: "none",
                  height: "10rem",
                }}
              ></textarea>
            </div>
            <div className="d-flex w-100">
              <div className="btn-group w-50 mx-auto">
                <Buttons
                  title={"approve"}
                  css="btn-success2 btn-success text-capitalize py-3 w-50 my-4 mx-auto"
                  width={"w-50"}
                  loading={loading}
                  onClick={handleSubmit("approve")}
                />
                <Buttons
                  title={"decline"}
                  css="btn-danger2 btn-danger text-capitalize py-3 w-50 my-4 mx-auto"
                  width={"w-50"}
                  loading={loading2}
                  onClick={handleSubmit("decline")}
                />
              </div>
            </div>
          </>
        )}
        <div className="ms-auto d-flex align-items-center">
          <button
            className="btn btn-danger-2 btn-danger2 d-block ms-auto me-2"
            onClick={() => {
              setIsOpen(null);
              setMainUser(false);
            }}
          >
            Close
          </button>
        </div>
      </ModalComponents>
    </div>
  );
};
