// Root reducer to combine all reducers in the app

import AuthReducer from "./AuthReducer";

import { combineReducers } from "redux";
import ErrorReducer, { PermissionReducer, SuccessReducer } from "./ErrorReducer";
import SettingsReducer, {
	NotificationReducer,
	SliderReducer,
} from "./SettingsReducer";
import UsersReducer, {
	AccountantsReducer,
	AgentsReducer,
	AllUsersReducer,
	DevelopersReducer,
	KeyPatronsReducer,
	ManagersReducer,
	MarketersReducer,
	ResellersReducer,
	SuperadminsReducer,
	TopusersReducer,
	TrackingWalletsReducer,
} from "./UserReducer";
import {
	BillerReducer,
	CategoryReducer,
	ChoosenReducer,
	NetworkReducer,
	OgdamsReducer,
	ProductReducer,
	RingoReducer,
	RPIDataReducer,
	MCDReducer,
	UzobesDataReducer,
	HonourworldReducer,
	GatewayReducer,
} from "./ProviderReducer";
import DataMainReducer, { DataBundleReducer } from "./DataReducer";
import UseCaseReducer, { UpgradeReducer } from "./UseCaseReducer";
import TransactionsReducer, { FailedTransactions, PendingTransctions } from "./TransactionsReducer";
import WalletReducer, {
	BonusReducer,
	CommissionReducer,
	ReferralReducer,
	WithdrawalReducer,
} from "./WalletReducer";
import AirtimeReducer, { AirtimeConverterReducer } from "./AirtimeReducer";
import socketReducer from "./SocketReducer";
import ElectricityReducer, {
	ElectricityBundleReducer,
} from "./ElectricityReducer";
import BizReducer, { ActivityReducer, VerifyReducer } from "./BizReducer";
import CableReducer, { CablesTypesReducer } from "./CableReducer";
import {
	FaqsReducer,
	ProviderFundingReducer,
	ProviderStateReducer,
	StartimesReducer,
} from "./StatReducer";
import { AutoBuyReducer } from "./AutoBuyReducer";
import EducationReducer, { EducationBundleReducer } from "./EducationReducer";
import {
	CgWalletHistoryReducer,
	CgWalletReducer,
	CgWalletUserHistoryReducer,
} from "./CgWalletReducer";
import { BlacklistReducer } from "./BlacklistReducer";

export default combineReducers({
	auth: AuthReducer,
	errors: ErrorReducer,
	settings: SettingsReducer,
	notifications: NotificationReducer,
	success: SuccessReducer,
	sliders: SliderReducer,
	chosen: ChoosenReducer,
	rpi: RPIDataReducer,
	ringo: RingoReducer,
	honourworld: HonourworldReducer,
	ogdams: OgdamsReducer,
	dataBundle: DataBundleReducer,
	category: CategoryReducer,
	biller: BillerReducer,
	products: ProductReducer,
	data: DataMainReducer,
	usecase: UseCaseReducer,
	upgrade: UpgradeReducer,
	network: NetworkReducer,
	transactions: TransactionsReducer,
	pendings: PendingTransctions,
	failedTransactions: FailedTransactions,
	user: UsersReducer,
	superadmin: SuperadminsReducer,
	manager: ManagersReducer,
	accountant: AccountantsReducer,
	marketer: MarketersReducer,
	developer: DevelopersReducer,
	reseller: ResellersReducer,
	agent: AgentsReducer,
	topuser: TopusersReducer,
	allUsers: AllUsersReducer,
	wallet: WalletReducer,
	bonus: BonusReducer,
	commission: CommissionReducer,
	airtimes: AirtimeReducer,
	converter: AirtimeConverterReducer,
	socket: socketReducer,
	electricity: ElectricityReducer,
	electricityBundle: ElectricityBundleReducer,
	education: EducationReducer,
	educationBundle: EducationBundleReducer,
	biz: BizReducer,
	mcd: MCDReducer,
	uzobest: UzobesDataReducer,
	cables: CableReducer,
	cablesBundle: CablesTypesReducer,
	activity: ActivityReducer,
	stat: ProviderStateReducer,
	auto_buy: AutoBuyReducer,
	withdrawal: WithdrawalReducer,
	referral: ReferralReducer,
	faqs: FaqsReducer,
	startimes: StartimesReducer,
	funding: ProviderFundingReducer,
	cgwallet: CgWalletReducer,
	cgwalletHistory: CgWalletHistoryReducer,
	permission: PermissionReducer,
	cgwalletUser: CgWalletUserHistoryReducer,
	gateway: GatewayReducer,
	trackingWallet: TrackingWalletsReducer,
	keyPatron: KeyPatronsReducer,
	blacklist: BlacklistReducer,
	verify: VerifyReducer,
});
