import {
  GET_USER,
  GET_USER_FAIL,
  GET_USER_LOADING,
  GET_USER_TYPES_COUNT,
  LOGIN_USER,
  LOGIN_USER_2FA,
  LOGIN_USER_FAIL,
  LOGOUT,
  REGISTER_USER,
  REGISTER_USER_FAIL,
  TOKEN,
  TOKEN_2FA,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_FAIL,
  UPDATE_USER,
  UPDATE_USER_FAIL,
} from "../Actions/ActionTypes";

const initialState = {
  user: null,
  token: "",
  isAuth: false,
  loading: false,
  isRegistered: false,
  isLoggedIn: false,
  isUpdated: false,
  isPassword: null,
  regCase: "",
  
};

const AuthReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOGIN_USER:
      localStorage.setItem(TOKEN, payload.token);
      const updatedState = {
        ...state,
        isLoggedIn: true,
        token: payload.token,
        user: payload?.data,
        is2FA: false,
      };

      return updatedState;
    case LOGIN_USER_2FA:
      localStorage.setItem(TOKEN_2FA, payload.token);
      return {
        ...state,
        is2FA: true,
      };
    case REGISTER_USER:
      return {
        ...state,
        isRegistered: true,
        regCase: payload?.data,
      };
    case LOGIN_USER_FAIL:
    case REGISTER_USER_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        isThirdPartyLoading: false,
        isRegistered: false,
      };
    case GET_USER:
      if (payload?.token) {
        localStorage.setItem(TOKEN, payload?.token);
      }
      return {
        ...state,
        user: payload?.data ? payload?.data : null,
        isAuth: payload?.data ? true : false,
        loading: false,
      };
    case GET_USER_FAIL:
      return {
        ...state,
        loading: false,
        isAuth: false,
      };
    case GET_USER_LOADING:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_USER:
      return {
        ...state,
        isUpdated: true,
        user: payload?.data,
      };
    case UPDATE_USER_FAIL:
      return { ...state, isUpdated: false };
    case UPDATE_PASSWORD:
      return { ...state, isPassword: true };
    case UPDATE_PASSWORD_FAIL:
      return { ...state, isPassword: false };
    case LOGOUT:
      // localStorage.removeItem("persist:root");
      localStorage.removeItem(TOKEN);
      return initialState;
    default:
      return state;
  }
};

export default AuthReducer;

export const EditData = (data, payload) => {
  let updatateData =
    data?.length > 0
      ? data.map((item) => (item._id !== payload._id ? item : payload))
      : data;
  return updatateData;
};

export const DeleteData = (data, payload) => {
  let filterItem =
    data?.length > 0
      ? [...data.filter((item) => item._id !== payload._id)]
      : [];
  return filterItem;
};
