import React, { useState, useContext, useEffect } from "react";
import { Container } from "reactstrap";
import {
  MainPaginate,
  TransactionDetails,
  TransactionsData,
} from "../../Components/Transactions";
import { GlobalState } from "../../Data/Context";
import { BottomTab } from "../../Components/LoadMore";

const FailedTransactions = () => {
  let { setStateName } = useContext(GlobalState);
  useEffect(() => {
    setStateName("Failed Transactions");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="bg-white">
      <Container>
        <SubFailedTransactionsFolder />
      </Container>
    </div>
  );
};

export default FailedTransactions;

export const SubFailedTransactionsFolder = () => {
  const { failedTransactions, getServicesHistoryFailed, getReload } =
    useContext(GlobalState);

  let [loading, setLoading] = useState(false),
    [search, setSearch] = useState(""),
    [state, setState] = useState(null),
    [thisData, setThisData] = useState(null);
  const [pageCount, setPageCount] = useState(1);

  useEffect(() => {
    getServicesHistoryFailed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (search) {
      document.getElementById("Search").addEventListener("search", () => {
        getReload();
      });
      let handleSubmit = async () => {
        if (!search) return;

        await getServicesHistoryFailed({
          search,
        });
      };
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (failedTransactions?.isFound) {
      setState(failedTransactions?.mainSearch);
    } else setState(failedTransactions?.transactions);
  }, [
    failedTransactions?.transactions,
    failedTransactions?.isFound,
    failedTransactions?.mainSearch,
  ]);

  useEffect(() => {
    getReload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (failedTransactions?.search_paginate) {
      setPageCount(
        failedTransactions?.search_paginate?.total /
          failedTransactions?.search_paginate?.limit
      );
    }
    setPageCount(
      Math.ceil(
        failedTransactions?.paginate?.total /
          failedTransactions?.paginate?.limit
      )
    );
  }, [
    failedTransactions?.paginate?.total,
    failedTransactions?.paginate?.limit,
  ]);

  let [range, setRange] = useState(10);

  if (!state) return <></>;

  const currentItems = state;

  const handlePageClick = async (event) => {
    if (search) {
      await getServicesHistoryFailed({
        page: Number(event.selected + 1),
        search,
      });
    } else {
      await getServicesHistoryFailed({
        page: Number(event.selected + 1),
      });
    }
  };

  return (
    <div className="py-5">
      <div className="w-50 w50">
        <input
          type="search"
          name="search"
          id="Search"
          className="form-control w-100 py-3 borderColor2"
          placeholder="Type here to search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>

      <TransactionsData
        state={currentItems}
        setThisData={setThisData}
        setState={setState}
        type={"all"}
        criteria={{
          limit: failedTransactions?.paginate?.limit,
          search: search ? search : "",
        }}
        mainState={state}
      />
      <MainPaginate handlePageClick={handlePageClick} pageCount={pageCount} />
      <BottomTab
        state={state}
        paginate={
          search
            ? failedTransactions?.search_paginate
            : failedTransactions?.paginate
        }
      />
      <TransactionDetails
        type={"all"}
        criteria={{
          limit: failedTransactions?.paginate?.limit,
          search: search ? search : "",
        }}
        thisData={thisData}
        setThisData={setThisData}
      />
    </div>
  );
};
