import React, { useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Container } from "reactstrap";
import { GlobalState } from "../../Data/Context";
// import AirtimeIcon from "../../../src/Assets/airtime.svg";
// import Cable from "../../../src/Assets/cable.svg";
// import Autobuy from "../../Assets/autobuy.svg";
// import Electricity from "../../Assets/electricity.svg";
// import Education from "../../Assets/education.svg";
// import Converter from "../../Assets/converter.svg";
// import Data from "../../Assets/data.svg";
// import UsersIcon from "../../Assets/users.svg";
// import Verify from "../../Assets/verify.svg";
// import Sales from "../../Assets/sales.svg";
// import Icon2 from "../../Assets/icon2.svg";
import {
  MdCategory,
  MdDataExploration,
  MdNetworkCell,
  MdOutlineCable,
  MdOutlineCastForEducation,
  MdOutlineManageAccounts,
  MdOutlineManageSearch,
  MdOutlineViewAgenda,
} from "react-icons/md";
import { FaHistory, FaProductHunt, FaWallet } from "react-icons/fa";
import { IoDiscOutline } from "react-icons/io5";
import { BiBlock } from "react-icons/bi";

const Controls = () => {
  let {
    setStateName,
    getBiller,
    // getBillerData, manageStartimes
  } = useContext(GlobalState);
  useEffect(() => {
    setStateName("Controls");
    // getBillerData("ringo");
    // getBillerData("smeplug");
    // getBillerData("ogdams");
    // getBillerData("uzobest");
    // getBillerData("chosen");
    // getBillerData("rpi");
    // manageStartimes("get");
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getBiller();
  }, []);
  let navigate = useNavigate(),
    params = useParams(),
    controlsTab = [
      {
        name: "manage biller",
        type: "link",
        link: `/${params?.page}/biller`,
        color: "linear-gradient(90deg, #DE0DE2 16.14%, #0E102D 101.45%)",
        textColor: "white",
        icon: <MdOutlineManageAccounts color="white" size={20} />,
      },
      {
        name: "manage gateway",
        type: "link",
        link: `/${params?.page}/gateway`,
        color: "linear-gradient(90.18deg, #6CB731 -52.19%, #0F5A16 81.92%)",
        textColor: "white",
        icon: <MdOutlineManageSearch color="white" size={20} />,
      },
      {
        name: "manage network",
        type: "link",
        link: `/${params?.page}/network`,
        color: "linear-gradient(96.86deg, #F2E553 18.88%, #FF9900 125.77%)",
        icon: <MdNetworkCell color="white" size={20} />,
      },
      {
        textColor: "white",
        name: "manage products",
        type: "link",
        link: `/${params?.page}/manage-products`,
        color: "linear-gradient(90deg, #F45F83 16.14%, #9E1A2A 101.45%)",
        icon: <FaProductHunt color="white" size={20} />,
      },
      {
        name: "manage category",
        type: "link",
        link: `/${params?.page}/category`,
        color: "linear-gradient(90.18deg, #3199B7 -52.19%, #144468 81.92%)",
        textColor: "white",
        icon: <MdCategory color="white" size={20} />,
      },
      {
        name: "manage data plans",
        type: "link",
        link: `/${params?.page}/data`,
        color:
          "linear-gradient(102deg, rgba(54,40,3,1) 55%, rgba(224,168,25,1) 100%)",
        icon: <MdDataExploration color="white" size={20} />,
      },
      {
        name: "manage cables subscription",
        type: "link",
        link: `/${params?.page}/cables`,
        color: "linear-gradient(90deg, #DE0DE2 16.14%, #0E102D 101.45%)",
        textColor: "white",
        icon: <MdOutlineCable color="white" size={20} />,
      },
      {
        name: "manage disco plans",
        type: "link",
        link: `/${params?.page}/electricity`,
        color:
          "linear-gradient(102deg, rgba(3,12,54,1) 55%, rgba(25,96,224,1) 100%)",
        icon: <IoDiscOutline color="white" size={20} />,
      },
      {
        name: "manage education",
        type: "link",
        link: `/${params?.page}/education`,
        color:
          "linear-gradient(90deg, rgba(228, 51, 105, 0.7) 16.14%, rgba(194, 14, 25, 0.7) 101.45%)",
        icon: <MdOutlineCastForEducation color="white" size={20} />,
      },
      {
        name: "manage startimes",
        type: "link",
        link: `/${params?.page}/startimes`,
        color: "linear-gradient(90.18deg, #3199B7 -52.19%, #144468 81.92%)",
        textColor: "white",
        icon: <MdOutlineViewAgenda color="white" size={20} />,
      },
      {
        name: "manage CG Wallet",
        type: "link",
        link: `/${params?.page}/manage-cgwallet`,
        color: "linear-gradient(96.86deg, #F2E553 18.88%, #FF9900 125.77%)",
        icon: <FaWallet color="white" size={20} />,
      },
      {
        name: "manage CG Wallet History",
        type: "link",
        link: `/${params?.page}/manage-cgwallet-history`,
        color: "linear-gradient(90.18deg, #6CB731 -52.19%, #0F5A16 81.92%)",
        icon: <FaHistory color="white" size={20} />,
        textColor: "white",
      },
      {
        name: "manage blacklist",
        type: "link",
        link: `/${params?.page}/blacklist`,
        color: "linear-gradient(90.18deg, #6CB731 -52.19%, #0F5A16 81.92%)",
        textColor: "white",
        icon: <BiBlock color="white" size={20} />,
      },
    ];

  return (
    <div className="bg-white aboutScreen">
      <Container className="tw-pt-10">
        <div className="tw-w-5/6 tw-mx-auto tw-grid lg:tw-grid-cols-4 tw-gap-4 tw-gap-y-10">
          {controlsTab?.map((item, i) => (
            <div
              onClick={() => {
                if (item?.type === "link") {
                  navigate(item?.link);
                } else if (item?.type === "button") {
                }
              }}
              style={{
                background: item?.color || "white",
              }}
              className="tw-flex tw-justify-between tw-cursor-pointer tw-h-24 tw-px-3 tw-shadow-lg tw-rounded-lg tw-items-center"
              key={i}
            >
              <div>{item?.icon}</div>
              <span
                className=""
                // style={{
                //   background: item?.color,
                //   borderRadius: "30px",
                //   color: item?.textColor ? item?.textColor : "#000",
                // }}
              >
                <span className="tw-text-base tw-text-white tw-font-medium tw-capitalize">
                  {item?.name}
                </span>
              </span>
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
};

export default Controls;
