import React, { useContext, useState, useEffect } from "react";
import Dashboard from "../Components/Dashboard";
import { GlobalState } from "../Data/Context";
// import icon1 from "../Assets/Group (4).png";
// import icon2 from "../Assets/Group (3).png";
// import icon3 from "../Assets/Group (3).png";
// import icon4 from "../Assets/Group (5).png";
import Icon5 from "../Assets/sales.svg";
import Icon7 from "../Assets/icon3.svg";
// import Icon8 from "../Assets/icon4.svg";
import Icon9 from "../Assets/pick.svg";
import Icon10 from "../Assets/confirm.svg";
import moment from "moment";
// import axios from "axios";
// import { useURL } from "../Data/Config";
import Store from "../Data/Store";
// import { type } from "@testing-library/user-event/dist/type";
import { getDashboardMetrics } from "../Data/Actions/GeneralAction";
import { TOKEN } from "../Data/Actions/ActionTypes";

const MainDashboard = () => {
  const {
    wallet,
    // allUsers,
    numberWithCommas,
    nairaSignNeutral,
    // pendings,
    // user,
    // agent,
    transactions,
    reseller,
    auth,
    getDashboardMetrics,
  } = useContext(GlobalState);

  useEffect(() => {
    if (wallet?.wallet_stat?.transactionType) {
      let newA = [];
      for (let w = 0; w < wallet?.wallet_stat?.transactionType.length; w++) {
        let element = wallet?.wallet_stat?.transactionType[w];

        let newP = {
          name: `${element?.transactionType?.replace(/[_]/g, " ")} Data Sold`,
          number: `${numberWithCommas(
            Number(element?.data || 0).toFixed(2)
          )}GB`,
          color: "linear-gradient(90.18deg, #6CB731 -52.19%, #0F5A16 81.92%)",
          icon: Icon7,
        };
        newA?.push(newP);
      }
      setUsersArr([...init, ...newA]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet?.wallet_stat]);

  let init = [
    {
      name: "total users",
      number: numberWithCommas(
        Number(
          // user?.paginate?.total || user?.all?.length
          transactions?.usersCount
          // ||
          // allUsers?.data?.filter((item) => !item?.isAdmin)?.length
        ) || 0
      ),
      color: "linear-gradient(90deg, #DE0DE2 16.14%, #0E102D 101.45%)",
      link: "/users/users",
      icon: Icon5,
    },
    {
      name: "total resellers",
      number: numberWithCommas(
        // reseller?.paginate?.total ||
        //   reseller?.all?.length ||
        Number(
          // allUsers?.data?.filter(
          //   (item) => !item?.isAdmin && item?.privilege === "reseller"
          // )?.length
          transactions?.resellersCount
        ) || 0
      ),
      color: "linear-gradient(90deg, #F45F83 16.14%, #9E1A2A 101.45%)",
      link: "/users/reseller",
      type: "amount",
      icon: Icon9,
    },
    {
      name: "total agents",
      number: numberWithCommas(
        // agent?.paginate?.total ||
        //   agent?.all?.length ||
        Number(
          // allUsers?.data?.filter(
          //   (item) => !item?.isAdmin && item?.privilege === "agent"
          // )?.length
          transactions?.agentsCount
        ) || 0
      ),
      color: "linear-gradient(90.18deg, #84C7DB -52.19%, #377FB6 81.92%)",
      link: "/users/agent",
      type: "amount",
      icon: Icon9,
    },
    {
      name: `${moment().format("MMMM")}'s transactions`,
      number: `${nairaSignNeutral}${numberWithCommas(
        Number(
          // wallet?.wallet_stat?.monthAmount ||
          //   wallet?.wallet_details?.transactions?.month ||
          //   wallet?.wallet_details?.month
          transactions?.transactionsCount?.monthly?.volume || 0
        ).toFixed(2)
      )}`,
      color: "linear-gradient(90.18deg, #3199B7 -52.19%, #144468 81.92%)",
      link: "/transactions",
      icon: Icon7,
    },
    {
      name: "todays' sales",
      number: `${nairaSignNeutral}${numberWithCommas(
        Number(
          // wallet?.wallet_stat?.dayAmount ||
          //   wallet?.wallet_details?.transactions?.day ||
          //   wallet?.wallet_details?.day
          transactions?.transactionsCount?.daily?.amount || 0
        ).toFixed(2)
      )}`,
      color: "linear-gradient(90.18deg, #84C7DB -52.19%, #377FB6 81.92%)",
      link: "/transactions",
      icon: Icon5,
    },
    {
      name: "total pendings",
      number:
        // pendings?.paginate?.total
        //   ? numberWithCommas(Number(pendings?.paginate?.total))
        //   : 0
        numberWithCommas(
          Number(transactions?.transactionsCount?.daily?.pendingCount) || 0
        ),

      color: "linear-gradient(96.86deg, #F2E553 18.88%, #FF9900 125.77%)",
      link: "/transactions/pending",
      icon: Icon10,
    },
    {
      name: "today's successful transaction count",
      number: numberWithCommas(
        Number(
          // wallet?.wallet_stat?.dayCount ||
          //   wallet?.wallet_details?.transactions?.dayCount ||
          //   wallet?.wallet_details?.dayCount ||
          //   0
          transactions?.transactionsCount?.daily?.count || 0
        )
      ),
      color: "linear-gradient(90.18deg, #6CB731 -52.19%, #0F5A16 81.92%)",
      icon: Icon10,
      button: true,
    },
    {
      name: "today's successful data sold",
      number: `${numberWithCommas(
        Number(
          // wallet?.wallet_stat?.dayDataCount ||
          //   wallet?.wallet_details?.transactions?.dayDataCount ||
          //   wallet?.wallet_details?.dayDataCount ||
          //   0
          transactions?.transactionsCount?.daily?.data?.volume || 0
        ).toFixed(2)
      )}GB`,
      color: "linear-gradient(90.18deg, #6CB731 -52.19%, #0F5A16 81.92%)",
      icon: Icon7,
      button: true,
    },
    {
      name: "failed transactions",
      number: numberWithCommas(
        Number(transactions?.transactionsCount?.daily?.failedCount) || 0
      ),
      color: "linear-gradient(90deg, #F45F83 16.14%, #9E1A2A 101.45%)",
      link: "/transactions/failed",
      icon: Icon5,
    },
  ];

  useEffect(() => {
    getDashboardMetrics();
  }, []);

  let [usersArr, setUsersArr] = useState(init);
  useEffect(() => {
    setUsersArr(init);
  }, [transactions]);

  return <Dashboard usersArr={usersArr} />;
};

export default MainDashboard;
