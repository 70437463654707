import React, { useContext, useEffect, useState } from "react";
import { Container } from "reactstrap";
import { Buttons } from "../../Utils";
import { ModalComponents } from "../../Components";
import { GlobalState } from "../../Data/Context";
import { useValidation } from "../../Data/useFetch";
import LoadMore, { BottomTab } from "../LoadMore";
import {
  TransactionDetails,
  TransactionToppers,
  NewPaginate,
} from "../Transactions";
import { TransactionPinBox } from "./AutoBuy";
import { NumericFormat } from "react-number-format";
import { toast } from "react-toastify";
import { BiCopy } from "react-icons/bi";
import Store from "../../Data/Store";
import { getElectricityToBuy } from "../../Data/Actions/DataActions";

const ElectricityBill = () => {
  let { setStateName, electricity, usecase } = useContext(GlobalState);
  let [isOpen, setIsOpen] = useState(false),
    toggle = () => {
      setIsOpen(!isOpen);
      if (clickedData) setClickedData(null);
    },
    [active, setActive] = useState(0),
    [active2, setActive2] = useState(0),
    btnTab = ["bill history", "bill list"];

  useEffect(() => {
    setStateName("bills history");
    Store.dispatch(getElectricityToBuy());
    setIsOpen(true);
    return () => setIsOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let [thisData, setThisData] = useState(false),
    [submit, setSubmit] = useState(null),
    [clickedData, setClickedData] = useState(null);

  useEffect(() => {
    if (submit && electricity?.isAdded) {
      toggle();
      setSubmit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit, electricity?.isAdded]);

  return (
    <div className="bg-white aboutScreen">
      <Container className="py-5">
        {usecase?.usecase?.electricity === "enable" && (
          <Buttons
            title={"pay bills"}
            css="btn-primary1 text-capitalize py-3 px-4 px-lg-5"
            width={"w-25 w25"}
            onClick={toggle}
            style={{ borderRadius: "30px" }}
          />
        )}
        <div className="btn-group w-100 py-3">
          {btnTab?.map((item, i) => (
            <button
              key={i}
              className={`btn py-3 text-capitalize fw-bold ${
                i === active ? "border-bottom textColor" : ""
              } rounded-0`}
              onClick={() => setActive(i)}
            >
              {item}
            </button>
          ))}
        </div>
        {active === 0 ? (
          <>
            <TransactionToppers
              title={"electricity"}
              active={active2}
              setActive={setActive2}
            />
            {active2 === 1 ? (
              <AllElectricityBillHistory
                setThisData={setThisData}
                thisData={thisData}
                active2={active2}
              />
            ) : (
              <ElectricityBillHistory
                setThisData={setThisData}
                thisData={thisData}
                active2={active2}
              />
            )}
          </>
        ) : (
          <div className="row mx-0">
            {electricity?.electricityToBuy?.map((item, i) => (
              <div
                onClick={
                  usecase?.usecase?.electricity === "enable"
                    ? () => setClickedData(item)
                    : () => {}
                }
                className="col-4 col-md-3 px-2 p-md-3 text-center dashHeight dashHeight2"
                key={i}
              >
                <div className="shadow2 p-3 p-md-4 eachProduct rounded20 h-100 d-flex align-items-center justify-content-center fontReduce2 flex-column">
                  <img
                    src={item?.image?.url}
                    alt={item?.disco}
                    className="img-fluid rounded imgFluid"
                    style={{
                      height: "auto",
                      width: "auto",
                    }}
                  />
                  <h5 className="pt-3 Lexend fw-bold">{item?.disco}</h5>
                </div>
              </div>
            ))}
          </div>
        )}
      </Container>
      <ModalComponents title="pay bills" isOpen={isOpen} toggle={toggle}>
        <ElectricityOptions
          setIsOpen={setIsOpen}
          submit={submit}
          setSubmit={setSubmit}
          clickedData={clickedData}
        />
      </ModalComponents>
    </div>
  );
};

export default ElectricityBill;

const ElectricityBillHistory = ({ setThisData, thisData, active2 }) => {
  const { electricity, getServicesHistory, getReload } =
    useContext(GlobalState);
  let [state, setState] = useState(null);

  let [loading, setLoading] = useState(false),
    [search, setSearch] = useState("");

  useEffect(() => {
    getServicesHistory("electricity");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (search) {
      document.getElementById("Search").addEventListener("search", () => {
        getReload();
      });
      let handleSubmit = async () => {
        if (!search) return;

        await getServicesHistory("electricity", {
          search,
        });
      };
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (electricity.isFound) {
      setState(electricity.mainSearch);
    } else setState(electricity.electricity);
  }, [electricity.electricity, electricity.isFound, electricity.mainSearch]);

  useEffect(() => {
    getReload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let handleLoadMore = async () => {
    setLoading(true);

    if (search) {
      await getServicesHistory("electricity", {
        page: Number(electricity?.paginate?.nextPage),
        // limit: Number(
        // 	electricity?.paginate?.nextPage * electricity?.paginate?.limit
        // ),
        search,
      });
    } else {
      await getServicesHistory("electricity", {
        page: Number(electricity?.paginate?.nextPage),
        // limit: Number(
        // 	electricity?.paginate?.nextPage * electricity?.paginate?.limit
        // ),
      });
    }
    setLoading(false);
  };

  if (!state) return;

  return (
    <div className="py-5">
      <div className="w-50 w50 mb-3">
        <input
          type="search"
          name="search"
          id="Search"
          className="form-control w-100 py-3 borderColor2"
          placeholder="Type here to search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <NewPaginate
        state={state}
        setState={setState}
        setThisData={setThisData}
        type={"electricity"}
        criteria={
          {
            // id: params?.step,
          }
        }
        my="myall"
      />
      <TransactionDetails
        thisData={thisData}
        setThisData={setThisData}
        type={"electricity"}
        criteria={
          {
            active: active2,
            search
          }
        }
      />
      <BottomTab
        state={state}
        paginate={search ? electricity?.search_paginate : electricity?.paginate}
      />
      {/* <LoadMore
        next={
          search
            ? electricity?.search_paginate?.next
            : electricity?.paginate?.next
        }
        handleLoadMore={handleLoadMore}
        loading={loading}
      /> */}
    </div>
  );
};

const AllElectricityBillHistory = ({ setThisData, thisData, active2 }) => {
  const { electricity, getAllServicesHistory, getReload } =
    useContext(GlobalState);
  let [state, setState] = useState(null);

  let [loading, setLoading] = useState(false),
    [search, setSearch] = useState("");

  useEffect(() => {
    getAllServicesHistory("electricity");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (search) {
      document.getElementById("Search").addEventListener("search", () => {
        getReload();
      });
      let handleSubmit = async () => {
        if (!search) return;

        await getAllServicesHistory("electricity", {
          search,
        });
      };
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (electricity.all_isFound) {
      setState(electricity.all_mainSearch);
    } else setState(electricity.all_electricity);
  }, [
    electricity.all_electricity,
    electricity.all_isFound,
    electricity.all_mainSearch,
  ]);

  useEffect(() => {
    getReload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let handleLoadMore = async () => {
    setLoading(true);

    await getAllServicesHistory("electricity", {
      page: Number(electricity?.all_paginate?.nextPage),
      // limit: Number(
      // 	electricity?.all_paginate?.nextPage * electricity?.all_paginate?.limit
      // ),
      search,
    });

    setLoading(false);
  };

  if (!state) return;

  return (
    <div className="py-5">
      <div className="w-50 w50 mb-3">
        <input
          type="search"
          name="search"
          id="Search"
          className="form-control w-100 py-3 borderColor2"
          placeholder="Type here to search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <NewPaginate
        state={state}
        setState={setState}
        setThisData={setThisData}
        type={"electricity"}
        criteria={
          {
            active: active2,
            search
          }
        }
      />
      <TransactionDetails
        thisData={thisData}
        setThisData={setThisData}
        type={"electricity"}
        criteria={
          {
            // id: params?.step,
          }
        }
      />
      <BottomTab
        state={state}
        paginate={
          search ? electricity?.all_search_paginate : electricity?.all_paginate
        }
      />
      {/* <LoadMore
        next={
          search
            ? electricity?.all_search_paginate?.next
            : electricity?.all_paginate?.next
        }
        handleLoadMore={handleLoadMore}
        loading={loading}
      /> */}
    </div>
  );
};

export const ElectricityOptions = ({
  setIsOpen,
  setSubmit,
  clickedData,
  auto,
  setAuto,
  setActive,
  data,
}) => {
  let {
    electricity,
    buyServices,
    returnErrors,
    nairaSignNeutral,
    wallet,
    numberWithCommas,
    usecase,
  } = useContext(GlobalState);
  let [buyActive, setBuyActive] = useState(0);

  let init = {
      type: "PREPAID",
      disco: "",
      meterNo: "",
      phoneNumber: "",
      amount: "",
      pin: "",
    },
    [state, setState] = useState(init),
    [newState, setNewState] = useState(null),
    [loading, setLoading] = useState(null),
    textChange =
      (name) =>
      ({ target: { value } }) => {
        setState({ ...state, [name]: value });
      },
    { handleFetch, validateLoading } = useValidation(
      "meterNo",
      state,
      setNewState
    );

  // useEffect(() => {
  // 	if (state?.meterNo?.length >= 10 && state?.type) handleFetch();
  // 	// eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [state?.meterNo, state?.type]);

  useEffect(() => {
    if (clickedData) {
      setState({ ...state, disco: clickedData?.disco });
      setIsOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickedData]);

  useEffect(() => {
    if (data?.properties) setState({ ...state, ...data?.properties });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (newState) {
      // console.log({ newState });
      setState({
        ...state,
        user: newState?.data,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newState]);
  useEffect(() => {
    if (validateLoading) {
      // console.log({ newState });
      setState({
        ...state,
        user: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validateLoading]);

  let handleSubmit = async (e) => {
    e?.preventDefault();
    if (!state?.meterNo) return;
    if (Number(state?.amount) <= 0)
      return returnErrors({
        error: [
          {
            msg: `Amount cannot be less than or equal to ${nairaSignNeutral} 0`,
            param: "amount",
          },
        ],
      });
    if (Number(state?.amount) < Number(usecase?.usecase?.electricityMini))
      return returnErrors({
        error: [
          {
            msg: `Amount cannot be less than ${nairaSignNeutral} ${numberWithCommas(
              Number(usecase?.usecase?.electricityMini)
            )}`,
            param: "amount",
          },
        ],
      });
    setLoading(true);
    await buyServices("electricity", state);
    setLoading(false);
    setSubmit(true);
  };

  useEffect(() => {
    if (state?.pin && state?.pin?.length === 4)
      if (auto) {
        setAuto({ ...data, properties: state });
        setActive(3);
      } else handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.pin, auto]);

  return (
    <div className="downH2 d-flex">
      {buyActive === 2 ? (
        <TransactionPinBox
          state={state}
          setState={setState}
          handleSubmit={handleSubmit}
          loading={loading || validateLoading}
        />
      ) : buyActive === 1 ? (
        <div className="w-100">
          <ElectricityDetails state={state} />
          {!auto && (
            <div className="d-flex justify-content-end">
              <Buttons
                title={"back"}
                css="btn-outline-primary1 text-capitalize"
                width={"w-auto"}
                onClick={() => {
                  setBuyActive(0);
                }}
              />
            </div>
          )}
          <Buttons
            title={
              validateLoading
                ? "Validating..."
                : auto || wallet?.balance?.wallet_pin
                ? "next"
                : "pay"
            }
            css="btn-primary1 text-capitalize py-3 px-4 px-lg-5 mx-auto"
            loading={loading || validateLoading}
            width={"w-50 w50"}
            onClick={
              wallet?.balance?.wallet_pin
                ? () => {
                    if (!state?.user) return;
                    setBuyActive(2);
                  }
                : auto
                ? () => {
                    setAuto({ ...data, properties: state });
                    setActive(3);
                  }
                : () => {
                    if (!state?.user) return;
                    handleSubmit();
                  }
            }
            style={{ borderRadius: "30px" }}
          />
        </div>
      ) : (
        <form className="w-100" onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="Type">Type</label>
            <select
              name="type"
              id="type"
              value={state?.type}
              onChange={textChange("type")}
              className="form-control form-select py-3 rounded20"
            >
              <option value="">Select bill type</option>
              <option value="PREPAID">PREPAID</option>
              <option value="POSTPAID">POSTPAID</option>
            </select>
          </div>
          <div className="mb-4">
            <label htmlFor="Disco">Disco</label>
            <select
              name="disco"
              id="disco"
              value={state?.disco}
              onChange={textChange("disco")}
              className="form-control form-select py-3 rounded20"
            >
              <option value="">Select bill disco</option>
              {electricity?.electricityToBuy?.map((item, i) => (
                <option value={item?.disco} key={i}>
                  {item?.fullname} ({item?.disco})
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label htmlFor="telephone">Meter Number</label>
            {/* <input
							type={"tel"}
							placeholder=""
							name="meterNo"
							value={state?.meterNo}
							onChange={textChange("meterNo")}
							className="form-control py-3"
							maxLength={10}
						/> */}
            <NumericFormat
              className="form-control py-3"
              value={state?.meterNo}
              allowLeadingZeros={true}
              allowNegative={false}
              displayType="input"
              name="meterNo"
              onValueChange={(val) =>
                setState({ ...state, meterNo: val?.value })
              }
              maxLength={15}
              decimalScale={0}
              inputMode="tel"
              renderText={(value, props) => <span {...props}>{value}</span>}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="value">Amount</label>
            {/* <input
							type={"number"}
							placeholder="500"
							className="form-control py-3"
							value={state?.amount}
							onChange={textChange("amount")}
							min={0}
						/> */}
            <NumericFormat
              prefix={`${nairaSignNeutral} `}
              className="form-control py-3"
              value={state?.amount}
              placeholder="500"
              displayType="input"
              thousandSeparator={true}
              onValueChange={(val) =>
                setState({ ...state, amount: val?.floatValue })
              }
              min={0}
              inputMode="decimal"
              renderText={(value, props) => <span {...props}>{value}</span>}
              allowNegative={false}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="telephone">Phone Number</label>
            {/* <input
							type={"tel"}
							placeholder="08012345678"
							className="form-control py-3"
							value={state?.phone}
							onChange={textChange("phone")}
							maxLength={11}
							/> */}
            <NumericFormat
              className="form-control py-3"
              value={state.phoneNumber}
              allowLeadingZeros={true}
              allowNegative={false}
              displayType="input"
              name="phone"
              onValueChange={(val) =>
                setState({ ...state, phoneNumber: val?.value })
              }
              maxLength={11}
              decimalScale={0}
              inputMode="tel"
              renderText={(value, props) => <span {...props}>{value}</span>}
            />
          </div>
          <Buttons
            title={
              validateLoading
                ? "Validating..."
                : !state?.user
                ? "Validate"
                : "proceed"
            }
            css="btn-primary1 text-capitalize py-3 px-4 px-lg-5 mx-auto"
            width={"w-50 w50"}
            loading={validateLoading}
            onClick={() => {
              if (!state?.meterNo || !state?.type) return;
              if (Number(state?.amount) <= 0)
                return returnErrors({
                  error: [
                    {
                      msg: `Amount cannot be less than or equal to ${nairaSignNeutral} 0`,
                      param: "amount",
                    },
                  ],
                });
              if (
                Number(state?.amount) <
                Number(usecase?.usecase?.electricityMini)
              )
                return returnErrors({
                  error: [
                    {
                      msg: `Amount cannot be less than ${nairaSignNeutral} ${numberWithCommas(
                        Number(usecase?.usecase?.electricityMini)
                      )}`,
                      param: "amount",
                    },
                  ],
                });
              if (state?.meterNo?.length < 10)
                return returnErrors({
                  error: [
                    {
                      msg: `Meter Number should not be less than 10 digits`,
                      param: "meterNo",
                    },
                  ],
                });
              if (!state?.phoneNumber?.startsWith("0"))
                return returnErrors({
                  error: [
                    {
                      msg: `${state?.phoneNumber} must start with a 0 digit`,
                      param: "telephone",
                    },
                  ],
                });
              if (!state?.user) handleFetch();
              else setBuyActive(1);
            }}
            style={{ borderRadius: "30px" }}
          />
        </form>
      )}
    </div>
  );
};

export const ElectricityDetails = ({ state }) => {
  let { nairaSign, numberWithCommas, settings } = useContext(GlobalState),
    [stateData, setStateData] = useState(null),
    [commission, setCommission] = useState(0);

  useEffect(() => {
    setStateData(settings?.settings);
  }, [settings?.settings]);
  useEffect(() => {
    if (state?.amount && stateData) {
      let com = stateData?.electricityCommission;
      setCommission(Number((com / 100) * state?.amount));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.amount, stateData]);

  return (
    <>
      <p className="text-capitalize border-bottom d-flex justify-content-between printOnlyNone">
        <span>Type: </span>
        <span className="fontInherit Lexend">{state?.type}</span>{" "}
      </p>
      <p className="text-capitalize border-bottom d-flex justify-content-between printOnlyNone">
        <span>Disco: </span>
        <span className="fontInherit Lexend">{state?.disco}</span>{" "}
      </p>
      <p className="text-capitalize border-bottom d-flex justify-content-between printOnlyNone">
        <span>Meter Number: </span>
        <span
          className="fontInherit Lexend force-d-flex myCursor"
          onClick={
            state?.meterNo
              ? () => {
                  navigator.clipboard.writeText(state?.meterNo).then(
                    () => {
                      toast.info("Copied", { autoClose: 2000 });
                    },
                    (err) => {
                      toast.warn(`Could not copy: ${err}`, {
                        autoClose: 2000,
                      });
                    }
                  );
                }
              : null
          }
        >
          {state?.meterNo}
          <BiCopy />
        </span>{" "}
      </p>
      <p className="text-capitalize border-bottom d-flex justify-content-between printOnlyNone">
        <span>Amount: </span>
        <span className="fontInherit Lexend">
          {nairaSign} {numberWithCommas(Number(state?.amount).toFixed(2))}
        </span>{" "}
      </p>
      <p className="text-capitalize border-bottom d-flex justify-content-between printOnlyNone">
        <span>Commission: </span>
        <span className="fontInherit Lexend">
          {nairaSign} {numberWithCommas(Number(commission || 0).toFixed(2))}
        </span>{" "}
      </p>
      {state?.phoneNumber && (
        <p className="text-capitalize border-bottom d-flex justify-content-between printOnlyNone">
          <span>Phone Number: </span>
          <span
            className="fontInherit Lexend force-d-flex myCursor"
            onClick={
              state?.phoneNumber
                ? () => {
                    navigator.clipboard.writeText(state?.phoneNumber).then(
                      () => {
                        toast.info("Copied", { autoClose: 2000 });
                      },
                      (err) => {
                        toast.warn(`Could not copy: ${err}`, {
                          autoClose: 2000,
                        });
                      }
                    );
                  }
                : null
            }
          >
            {state?.phoneNumber}
            <BiCopy />
          </span>{" "}
        </p>
      )}
      <p className="text-capitalize border-bottom d-flex justify-content-between printOnlyNone">
        <span>Customer name: </span>
        <span className="fontInherit Lexend">
          {state?.user?.customerName}
        </span>{" "}
      </p>
      <p className="text-capitalize border-bottom d-flex justify-content-between printOnlyNone">
        <span>Customer address: </span>
        <span className="fontInherit Lexend">
          {state?.user?.customerAddress}
        </span>{" "}
      </p>
    </>
  );
};
