import React, { useState, useContext, useEffect } from "react";
import { Container } from "reactstrap";
import {
  MainPaginate,
  MainRanger,
  TransactionDetails,
  TransactionsData,
} from "../../Components/Transactions";
import { GlobalState } from "../../Data/Context";
import LoadMore, { BottomTab } from "../../Components/LoadMore";

const PendingTransactions = () => {
  let { setStateName } = useContext(GlobalState);
  useEffect(() => {
    setStateName("Pending Transactions");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="bg-white">
      <Container>
        <SubPendingTransactionsFolder />
      </Container>
    </div>
  );
};

export default PendingTransactions;

export const SubPendingTransactionsFolder = () => {
  const { pendings, getServicesHistoryPending, getReload } =
    useContext(GlobalState);

  let [loading, setLoading] = useState(false),
    [search, setSearch] = useState(""),
    [state, setState] = useState(null),
    [thisData, setThisData] = useState(null);
  const [pageCount, setPageCount] = useState(1);

  useEffect(() => {
    if (search) {
      document.getElementById("Search").addEventListener("search", () => {
        getReload();
      });
      let handleSubmit = async () => {
        if (!search) return;

        await getServicesHistoryPending({
          search,
        });
      };
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (pendings.isFound) {
      setState(pendings.mainSearch);
    } else setState(pendings.transactions);
  }, [pendings.transactions, pendings.isFound, pendings.mainSearch]);

  useEffect(() => {
    getReload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getServicesHistoryPending();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (pendings?.search_paginate) {
      setPageCount(
        pendings?.search_paginate?.total / pendings?.search_paginate?.limit
      );
    }
    setPageCount(
      Math.ceil(pendings?.paginate?.total / pendings?.paginate?.limit)
    );
  }, [pendings?.paginate?.total, pendings?.paginate?.limit]);

  // let handleLoadMore = async () => {
  //   setLoading(true);

  //   if (search) {
  //     await getServicesHistoryPending({
  //       limit: Number(pendings?.paginate?.nextPage * pendings?.paginate?.limit),
  //       search,
  //     });
  //   } else {
  //     await getServicesHistoryPending({
  //       limit: Number(pendings?.paginate?.nextPage * pendings?.paginate?.limit),
  //     });
  //   }
  //   setLoading(false);
  // };

  let [range, setRange] = useState(10);

  const [itemOffset, setItemOffset] = useState(0);

  const endOffset = itemOffset + range;

  if (!state) return <></>;

  // const currentItems = state.slice(itemOffset, endOffset);

  const currentItems = state;

  // const pageCount = Math.ceil(state.length / range);

  const handlePageClick = async (event) => {
    // const newOffset = (event.selected * range) % state.length;
    // setItemOffset(newOffset);
    if (search) {
      await getServicesHistoryPending({
        // limit: Number(pendings?.paginate?.nextPage * pendings?.paginate?.limit),
        page: Number(event.selected + 1),
        search,
      });
    } else {
      await getServicesHistoryPending({
        // limit: Number(pendings?.paginate?.nextPage * pendings?.paginate?.limit),
        page: Number(event.selected + 1),
      });
    }
  };

  console.log(pendings);

  return (
    <div className="py-5">
      <div className="w-50 w50">
        <input
          type="search"
          name="search"
          id="Search"
          className="form-control w-100 py-3 borderColor2"
          placeholder="Type here to search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      {/* <MainRanger range={range} setRange={setRange} /> */}

      <TransactionsData
        state={currentItems}
        setThisData={setThisData}
        setState={setState}
        type={"all"}
        criteria={{
          limit: pendings?.paginate?.limit,
          search: search ? search : "",
        }}
        mainState={state}
      />
      <MainPaginate handlePageClick={handlePageClick} pageCount={pageCount} />
      <BottomTab
        state={state}
        paginate={search ? pendings?.search_paginate : pendings?.paginate}
      />
      {/* <LoadMore
        next={
          search ? pendings?.search_paginate?.next : pendings?.paginate?.next
        }
        handleLoadMore={handleLoadMore}
        loading={loading}
      /> */}
      <TransactionDetails
        type={"all"}
        criteria={{
          limit: pendings?.paginate?.limit,
          search: search ? search : "",
        }}
        thisData={thisData}
        setThisData={setThisData}
      />
    </div>
  );
};
